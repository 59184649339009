import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from './accounts-routing.module';
import { OverviewComponent } from './components/overview/overview.component';
import { SearchComponent } from './components/search/search.component';
import {SharedModule} from "../shared/shared.module";
import { AccountDetailsComponent } from './components/account-details/account-details.component';


@NgModule({
  declarations: [OverviewComponent, SearchComponent, AccountDetailsComponent],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    SharedModule,
    SharedModule,
    SharedModule
  ]
})
export class AccountsModule { }
