import {HttpClient} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {
  CreditCardInfoTO,
  OpenPaymentInfoTO,
  InvoiceTO,
  UpcomingPaymentTO, VolumeCustomerTO
} from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(
    @Inject('VOTING_SERVER_URL') private serverUrl: string,
    private http: HttpClient
  ) {
  }


  public getExpiringCards(): Observable<CreditCardInfoTO[]> {
    const url = this.serverUrl + ApiEndpoints.getExpiringCards;
    return this.http.get<CreditCardInfoTO[]>(url);
  }

  public notifyCardHoldingAccount(infoTO: CreditCardInfoTO) {
    const url = this.serverUrl + ApiEndpoints.notifyCardHoldingAccount;
    return this.http.put(url, infoTO);
  }

  public findOpenInvoices(): Observable<InvoiceTO[]> {
    const url = this.serverUrl + ApiEndpoints.findOpenInvoices;
    return this.http.get<InvoiceTO[]>(url);
  }

  public rebookInvoice(invoiceId: number, notify: boolean) {
    const url = this.serverUrl + ApiEndpoints.rebookInvoice;
    return this.http.put(url, null, {params: {invoiceId: invoiceId.toString(), notify: String(notify)}});
  }

  public processOpenPayments() {
    const url = this.serverUrl + ApiEndpoints.processOpenPayments;
    return this.http.put(url, null);
  }

  public processSinglePayment(paymentId: number) {
    const url = this.serverUrl + ApiEndpoints.processOpenPayment + paymentId.toString();
    return this.http.put(url, null);
  }

  public loadOpenPayments(): Observable<OpenPaymentInfoTO> {
    const url = this.serverUrl + ApiEndpoints.openPayments;
    return this.http.get<OpenPaymentInfoTO>(url);
  }

  public createOpenPaymentsForDueAccounts() {
    const url = this.serverUrl + ApiEndpoints.createOpenPaymentsForDueSubscriptions;
    return this.http.put(url, null);
  }

  public loadUpcomingPayments(): Observable<UpcomingPaymentTO[]> {
    const url = this.serverUrl + ApiEndpoints.upcomingPayments;
    return this.http.get<UpcomingPaymentTO[]>(url);
  }

  public loadDueVolumeCustomers(): Observable<VolumeCustomerTO[]> {
    const url = this.serverUrl + ApiEndpoints.loadDueVolumeCustomers;
    return this.http.get<VolumeCustomerTO[]>(url);
  }

}
