<h2>Calculate Upgrade Prices</h2>
<mat-dialog-content>
  <div *ngIf="!result; else res">
    <form novalidate [formGroup]="formGroup">
      <div class="two fields">
        <mat-form-field>
          <mat-label>Price per Licence*</mat-label>
          <input matInput formControlName="pricePerLicence" name="pricePerLicence" type="number"
                 placeholder="24.99"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Amount of licences*</mat-label>
          <input matInput formControlName="amountLicences" name="amountLicences" type="number"
                 placeholder="3"/>
        </mat-form-field>
      </div>
      <div class="two fields">
        <mat-form-field>
          <mat-label>Invoice intervall in month*</mat-label>
          <input matInput formControlName="intervalInMonths" name="intervalInMonths" type="number"
                 placeholder="3"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Valid until*</mat-label>
          <input matInput formControlName="validUntil" [matDatepicker]="datePicker"/>
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <div class="ti actions">
      <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
      <button [disabled]="formGroup.invalid" (click)="calculate()" mat-flat-button
              color="accent">Calculate
      </button>
    </div>
  </div>
  <ng-template #res>
    <div class="calc-cards">
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle>Price per licence</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{result.pricePerLicence | currency:'EUR':true}}
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          *
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle>Amount of licences</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{result.amountLicences}}
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          =
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle>Total price</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{result.totalPrice | currency:'EUR':true}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="text-card">
      <mat-card>
        <mat-card-content>
          {{result.upgradeIntervalString}}
        </mat-card-content>
      </mat-card>
    </div>
    <div class="ti actions">
      <button mat-button (click)="result = undefined">New Calculation</button>
      <button (click)="createInvoice()" mat-flat-button color="accent">Create Invoice</button>
    </div>
  </ng-template>
</mat-dialog-content>

