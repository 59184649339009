import {Component, Inject, OnInit} from '@angular/core';
import {BillingAddressTO, ContactPersonTO} from "../../generated/transportObjects";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../util/application-helper";

@Component({
  selector: 'pa-edit-create-contact-person-modal',
  templateUrl: './edit-create-contact-person-modal.component.html',
  styleUrls: ['./edit-create-contact-person-modal.component.scss']
})
export class EditCreateContactPersonModalComponent implements OnInit {

  public readonly editMode: boolean;
  contact: ContactPersonTO;

  constructor(
    public dialogRef: MatDialogRef<EditCreateContactPersonModalComponent, ContactPersonTO>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(data);
    this.contact = data;
  }

  ngOnInit(): void {
  }



}
