import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SideNavElement, SideNavService} from 'ti-frontend-shared';
import {Router, RouterEvent} from '@angular/router';

@Component({
  selector: 'pa-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  private routerSubscription: Subscription;
  public visibleNavElements: SideNavElement[] = [];

  private licenseManagementNavElements: SideNavElement[] = [
    {icon: 'business', target: 'volume-customers/all', title: 'All'},
    {icon: 'lock_clock', target: 'volume-customers/requests', title: 'Requests'},
  ];

  private accountsNavElements: SideNavElement[] = [
    {icon: 'contacts', target: 'accounts/overview', title: 'Overview'},
    {icon: 'person_search', target: 'accounts/search', title: 'Search'},
  ];

  private paymentNavElements: SideNavElement[] = [
    {icon: 'payments', target: 'payment/payment-service', title: 'Service'},
    {icon: 'credit_card', target: 'payment/credit-cards', title: 'Credit Cards'},
    {icon: 'request_quote', target: 'payment/invoices', title: 'Invoices'},
  ];

  private reportsNavElements: SideNavElement[] = [
    {icon: 'notes', target: 'reports/logs', title: 'Logs'},
    {icon: 'analytics', target: 'reports/metrics', title: 'Metrics'},
  ];

  constructor(
    private navService: SideNavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof RouterEvent) {
        const url = (event as RouterEvent).url;
        if (url.startsWith('/volume-customers')) {
          this.visibleNavElements = this.licenseManagementNavElements;
        }
        if (url.startsWith('/accounts')) {
          this.visibleNavElements = this.accountsNavElements;
        }
        if (url.startsWith('/payment')) {
          this.visibleNavElements = this.paymentNavElements;
        }
        if (url.startsWith('/reports')) {
          this.visibleNavElements = this.reportsNavElements;
        }
      }
    }, error => {}, () => {});
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

}
