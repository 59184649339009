<div class="account-search-container">
  <div class="header">
    <h1>Account Search</h1>
  </div>
  <div class="search-section">
    <form novalidate [formGroup]="formGroup">
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input #name maxlength="120" type="text" matInput placeholder="testuser@test.de" formControlName="name" (keyup.enter)="search()" />
            <mat-icon matSuffix>search</mat-icon>
            <mat-hint align="start"><strong>Just press enter to perform search.</strong> </mat-hint>
            <mat-hint align="end">{{ name.value?.length || 0 }}/120</mat-hint>
          </mat-form-field>
    </form>
  </div>
  <div class="result-section">
    <div *ngIf="accounts && accounts.length > 0; else noAccounts">
      <table mat-table [dataSource]="tableDataSource">
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef>Owner</th>
          <td  mat-cell *matCellDef="let acc">
            <span>{{acc.username}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td  mat-cell *matCellDef="let acc">
            <span>{{acc.created | date: "dd.MM.yyyy"}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="vc">
          <th mat-header-cell *matHeaderCellDef>VC</th>
          <td  mat-cell *matCellDef="let acc">
            <span [ngClass]="acc.vcName ? 'link' : ''" (click)="goToDetails(acc.vcId)">{{acc.vcName ? acc.vcName : 'No'}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="lastActiveSubscription">
          <th mat-header-cell *matHeaderCellDef>Last active subscription</th>
          <td  mat-cell *matCellDef="let acc">
            <span>{{acc.lastActiveSubscription | date: "dd.MM.yyyy"}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef>Last login</th>
          <td  mat-cell *matCellDef="let acc">
            <span>{{acc.lastLogin | date: "dd.MM.yyyy"}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="canceled">
          <th mat-header-cell *matHeaderCellDef>Canceled</th>
          <td  mat-cell *matCellDef="let acc">
            <span>{{acc.canceled | date: "dd.MM.yyyy"}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
        <tr class="selectable-row" [routerLink]="'/accounts/' + acc.id + '/details'" mat-row *matRowDef="let acc; columns: visibleColumns"></tr>
      </table>
    </div>
    <mat-paginator
      *ngIf="page && page.content.length > 0"
      [length]="page.totalElements"
      (page)="processPageEvent($event)"
      [pageSize]="25"
      [pageSizeOptions]="[10, 25, 100]"
    ></mat-paginator>

    <ng-template #noAccounts>
      <div class="no-accounts">
        <span class="material-icons">supervisor_account</span>
        <div>No Accounts found</div>
      </div>
    </ng-template>

  </div>
</div>
