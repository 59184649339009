import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactPersonTO} from "../../generated/transportObjects";
import {ApplicationHelper} from "../../util/application-helper";

@Component({
  selector: 'pa-edit-create-contact-person',
  templateUrl: './edit-create-contact-person.component.html',
  styleUrls: ['./edit-create-contact-person.component.scss']
})
export class EditCreateContactPersonComponent implements OnInit {
  public formGroup: FormGroup;
  @Input()
  contact: ContactPersonTO;
  @Output()
  onCancelClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSaveClick: EventEmitter<ContactPersonTO> = new EventEmitter<ContactPersonTO>();

  readonly salutations;

  constructor(private fb: FormBuilder) {
    this.salutations = ApplicationHelper.salutations;
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.contact) {
      this.formGroup.patchValue(this.contact);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      salutation: this.fb.control(undefined, Validators.required),
      firstName: this.fb.control(undefined, Validators.required),
      lastName: this.fb.control(undefined, Validators.required),
      email: this.fb.control(undefined, Validators.email),
      phone: this.fb.control(undefined),
      mobile: this.fb.control(undefined),
      info: this.fb.control(undefined),
      department: this.fb.control(undefined),
    });
  }

}
