import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  CreditCardInfoTO
} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-credit-cards-list',
  templateUrl: './credit-cards-list.component.html',
  styleUrls: ['./credit-cards-list.component.scss']
})
export class CreditCardsListComponent implements OnInit, AfterViewInit {

  @Input()
  cards: CreditCardInfoTO[] = [];

  @Output()
  onNotifyClicked: EventEmitter<CreditCardInfoTO> = new EventEmitter<CreditCardInfoTO>();

  @Input()
  public visibleColumns: string[] = ["cardType", "cardHolder", "expires", "nextBillingDate", "nextBillingAmount", "notifications", "actions"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<CreditCardInfoTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<CreditCardInfoTO>(this.cards);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<CreditCardInfoTO>(this.cards);
      this.tableDataSource.paginator = this.paginator;
    });
  }
}

