import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PollyAdminGuard} from "../core/guards/polly-admin-guard.service";
import {LogsComponent} from "./components/logs/logs.component";
import {MetricsComponent} from "./components/metrics/metrics.component";

const basePath: string = "reports/";
const routes: Routes = [
  {
    path: basePath + "logs",
    component: LogsComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "metrics",
    component: MetricsComponent,
    canActivate: [PollyAdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
