<pa-sub-navigation target="/volume-customers/requests">overview
</pa-sub-navigation>

<div *ngIf="businessCustomerRequestTO" class="business-customer-request-detail-container">
  <div class="header">
    <h1>{{businessCustomerRequestTO.companyName}} ({{businessCustomerRequestTO.amountLicences}}
      licences
      )</h1>
    <div class="flex-spacer"></div>
    <div>
      <button (click)="copyAccessLink()" mat-fab matTooltip="Copy link for this request">
        <mat-icon
          *ngIf="!copied" class="animated fadeIn">link
        </mat-icon>
        <mat-icon
          *ngIf="copied" class="animated fadeIn">done
        </mat-icon>
      </button>
    </div>
    <div>
      <button (click)="editBusinessCustomerRequest()" mat-fab matTooltip="Edit business customer request">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div *ngIf="transferable">
      <button (click)="transferBusinessCustomerRequest()" mat-fab matTooltip="Transfer request to volume customer">
        <mat-icon>send</mat-icon>
      </button>
    </div>
    <div>
      <button (click)="deleteBusinessCustomerRequest()" mat-fab color="warn" matTooltip="Delete business customer request">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <mat-chip-list>
    <mat-chip color="primary-accent" *ngIf="businessCustomerRequestTO.approved">Approved</mat-chip>
    <mat-chip color="sub-accent" *ngIf="!businessCustomerRequestTO.approved">Created</mat-chip>
    <mat-chip color="warn" *ngIf="isExpired(businessCustomerRequestTO.expiring)">Expired</mat-chip>
  </mat-chip-list>
  <div class="business-customer-request-detail">
    <div class="left">
      <div *ngIf="businessCustomerRequestTO.adminMailAddress">Administrator:</div>
      <div>Created:</div>
      <div>Expiring:</div>
      <div *ngIf="businessCustomerRequestTO.approved">Approved:</div>
    </div>
    <div class="right">
      <div *ngIf="businessCustomerRequestTO.adminMailAddress">{{businessCustomerRequestTO.adminMailAddress}}</div>
      <div>{{businessCustomerRequestTO.created | date: 'dd. MMMM yyyy - HH:mm'}}</div>
      <div>{{businessCustomerRequestTO.expiring | date: 'dd. MMMM yyyy - HH:mm'}}</div>
      <div
        *ngIf="businessCustomerRequestTO.approved">{{businessCustomerRequestTO.approved | date: 'dd. MMMM yyyy - HH:mm'}}</div>
    </div>
  </div>
  <div class="detail-sub-section-container">


  <div class="detail-sub-section">
    <pa-details-sub-section [displayButton]="false" [headline]="'Contact'" [isDataDefined]="businessCustomerRequestTO.billingAddress" [noContentMessage]="'No contact added yet'">
      <div class="business-customer-request-contact">
        <div class="left">
          <div>Firstname:</div>
          <div>Lastname:</div>
          <div>Mail:</div>
          <div>Phone:</div>
        </div>
        <div class="right">
          <div>
            {{businessCustomerRequestTO.contactFirstName || 'n/a'}}
          </div>
          <div> {{businessCustomerRequestTO.contactLastName || 'n/a'}}</div>
          <div>
            {{businessCustomerRequestTO.contactMailAddress || 'n/a'}}
          </div>
          <div> {{businessCustomerRequestTO.phoneNumber|| 'n/a'}}</div>
        </div>
      </div>
    </pa-details-sub-section>
  </div>
  <div class="detail-sub-section">
    <pa-details-sub-section [displayButton]="false" [headline]="'Billing Address'" [isDataDefined]="businessCustomerRequestTO.billingAddress" [noContentMessage]="'No billing address added yet'">
      <pa-show-billing-address [billingAddress]="businessCustomerRequestTO.billingAddress"></pa-show-billing-address>
    </pa-details-sub-section>
  </div>
  </div>
</div>
