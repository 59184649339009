import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PollyAdminGuard} from "../core/guards/polly-admin-guard.service";
import {OverviewComponent} from "./components/overview/overview.component";
import {SearchComponent} from "./components/search/search.component";
import {AccountDetailsComponent} from "./components/account-details/account-details.component";

const basePath: string = "accounts/";
const routes: Routes = [
  {
    path: basePath + "overview",
    component: OverviewComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "search",
    component: SearchComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + ":accountId/details",
    component: AccountDetailsComponent,
    canActivate: [PollyAdminGuard]
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
