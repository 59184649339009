import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ApplicationHelper, LogFilterTO, MonitoringEntryClientTO, Page} from "ti-frontend-shared";
import {Observable} from "rxjs";
import {ApiEndpoints} from "../../shared/util/api-endpoints";

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }


  public loadAllLogs(filter: LogFilterTO): Observable<Page<MonitoringEntryClientTO>> {
    const url: string = this.adminServerUrl + ApiEndpoints.applicationLogs;
    return this.http.get<Page<MonitoringEntryClientTO>>(url, {params: this.createFilterParameter(filter)});
  }

  private createFilterParameter(filter: LogFilterTO): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append("page", filter.page.toString());
    params = params.append("size", filter.size.toString());
    if (ApplicationHelper.isObjectDefined(filter.application)) {
      params = params.append("application", filter.application);
    }
    if (ApplicationHelper.isObjectDefined(filter.loggingReference)) {
      params = params.append("loggingReference", filter.loggingReference);
    }
    if (ApplicationHelper.isObjectDefined(filter.loggingCategory)) {
      params = params.append("loggingCategory", filter.loggingCategory);
    }
    if (ApplicationHelper.isObjectDefined(filter.loggingReferenceId)) {
      params = params.append("loggingReferenceId", filter.loggingReferenceId.toString());
    }
    if (ApplicationHelper.isObjectDefined(filter.severity)) {
      params = params.append("severity", filter.severity);
    }
    if (ApplicationHelper.isObjectDefined(filter.logTimestampFrom)) {
      params = params.append("logTimestampFrom", filter.logTimestampFrom);
    }
    if (ApplicationHelper.isObjectDefined(filter.logTimestampTo)) {
      params = params.append("logTimestampTo", filter.logTimestampTo);
    }
    return params;
  }

}
