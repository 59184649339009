<div *ngIf="address" class="show-address-container">
  <div class="labels">
    <div>Street:</div>
    <div>ZIP:</div>
    <div>City</div>
  </div>
  <div class="values">
    <div>{{address.street}} {{address.streetNumber}}</div>
    <div>{{address.zip}}</div>
    <div>{{address.city}}</div>
  </div>
</div>
