import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OpenPaymentTO} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-open-payments-list',
  templateUrl: './open-payments-list.component.html',
  styleUrls: ['./open-payments-list.component.scss']
})
export class OpenPaymentsListComponent implements OnInit, AfterViewInit {

  @Input()
  openPayments: OpenPaymentTO[] = [];

  @Output()
  onOpenPaymentClicked: EventEmitter<OpenPaymentTO> = new EventEmitter<OpenPaymentTO>();

  @Input()
  public visibleColumns: string[] = ["owner", "amount", "period", "type", "errors", "reverse-charge"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<OpenPaymentTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<OpenPaymentTO>(this.openPayments);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<OpenPaymentTO>(this.openPayments);
      this.tableDataSource.paginator = this.paginator;
    });
  }
}

