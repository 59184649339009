import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  VCInvoiceTO,
  VolumeCustomerTO
} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ModalService} from "../../../core/services/modal.service";
import {VolumeCustomerService} from "../../services/volume-customers.service";
import {DatePipe} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {saveAs} from 'file-saver';

@Component({
  selector: 'pa-vc-invoices-list',
  templateUrl: './vc-invoices-list.component.html',
  styleUrls: ['./vc-invoices-list.component.scss']
})
export class VcInvoicesListComponent implements OnInit, AfterViewInit {

  invoices: VCInvoiceTO[] = [];
  @Input()
  volumeCustomer: VolumeCustomerTO;

  @Output()
  onRequestSelect: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public visibleColumns: string[] = ["created", "invoiceNumber", "netAmount","actions"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public tableDataSource: MatTableDataSource<VCInvoiceTO>;

  constructor(private ms: ModalService, private volumeCustomerService: VolumeCustomerService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.volumeCustomerService.getInvoicesForVC(this.volumeCustomer.id).subscribe(value => {
      this.invoices = value;
      this.updateTable();
    });
    this.tableDataSource = new MatTableDataSource<VCInvoiceTO>(this.invoices);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<VCInvoiceTO>(this.invoices);
      this.tableDataSource.paginator = this.paginator;
    });
  }

  public get sales(): number {
    return this.invoices.map(value => value.net).reduce((previousValue, currentValue) => previousValue + currentValue);
  }

  openCreateInvoiceModal() {
this.ms.openCreateVCInvoiceModal().afterClosed().subscribe(value => {
if (value) {
  this.volumeCustomerService.createInvoiceForVC(this.volumeCustomer.id, value).subscribe(() => {}, () => {}, () => {
  this.volumeCustomerService.getInvoicesForVC(this.volumeCustomer.id).subscribe(value1 => {
    this.invoices = value1;
    this.updateTable();
  });
  });
}
});
  }

  printInvoice(invoice: VCInvoiceTO) {
    this.volumeCustomerService.printInvoice(invoice.id).subscribe(res => saveAs(res, invoice.invoiceNumberString + '.pdf'));
  }
}
