<div *ngIf="account" class="account-detail-container">
  <div class="header">
    <h1>{{account.owner}}</h1>
    <div class="flex-spacer"></div>
    <button (click)="checkDeleteAccount()" mat-fab color="warn"><mat-icon>remove</mat-icon></button>
  </div>
  <div class="quick-info">
    <div *ngIf="account.accountCreated">
      <mat-icon>verified</mat-icon>
      Account created at {{account.accountCreated | date: 'dd.MM.yyyy HH:MM'}}
    </div>
    <div *ngIf="account.lastLogin">
      <mat-icon>login</mat-icon>
      Last login: {{account.lastLogin | date: 'dd.MM.yyyy HH:MM'}}
    </div>
    <div *ngIf="account.vcClient">
      <mat-icon>supervisor_account</mat-icon>
      Volume Client
    </div>
    <div *ngIf="account.accountConfiguration">
      <mat-icon>invert_colors</mat-icon>
      Account customized
    </div>
    <div *ngIf="account.canceled">
      <mat-icon>dangerous</mat-icon>
      Account canceled
    </div>
    <div *ngIf="account.lastActiveSubscription">
      <mat-icon>edit_off</mat-icon>
      last active subscription: {{account.lastActiveSubscription | date: 'dd.MM.yyyy'}}
    </div>
  </div>

  <div class="subscription-info">
    <div *ngIf="account.subscription" class="current">
      <h4>Subscription info</h4>
      <div>Account type: {{account.subscription.type}}</div>
      <div>Subscription valid until {{account.subscription.validUntil | date: 'dd.MM.yyyy'}}</div>
      <div>Interval: {{account.subscription.subscriptionInterval}}</div>
      <div *ngIf="account.subscription.price">Price: {{account.subscription.price}} EUR</div>
      <div *ngIf="account.subscription.recalled" class="canceled">*** subscription recalled ***</div>
    </div>
    <div *ngIf="account.scheduledSubscription" class="future">
      <h4>Scheduled subscription</h4>
      <div>Account type: {{account.scheduledSubscription.type}}</div>
      <div>Subscription valid at {{account.scheduledSubscription.start | date: 'dd.MM.yyyy'}}</div>
      <div>Interval: {{account.scheduledSubscription.subscriptionInterval}}</div>
      <div *ngIf="account.scheduledSubscription.price">Price: {{account.scheduledSubscription.price}} EUR</div>
    </div>
  </div>

  <div *ngIf="account.invoices && account.invoices.length > 0" class="invoice-info">
    <h3>Account invoices</h3>
    <table mat-table [dataSource]="invoiceDataSource">
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Invoice created</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.created | date: 'dd.MM.yyyy HH:MM'}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="startPeriod">
        <th mat-header-cell *matHeaderCellDef>Start</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.startPeriod | date: "dd.MM.yyyy"}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="endPeriod">
        <th mat-header-cell *matHeaderCellDef>End</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.endPeriod | date: "dd.MM.yyyy"}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="netAmount">
        <th mat-header-cell *matHeaderCellDef>Net amount</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.netAmount}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="taxInPercent">
        <th mat-header-cell *matHeaderCellDef>Tax</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.taxInPercent}}%</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="grossAmount">
        <th mat-header-cell *matHeaderCellDef>Gross amount</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.grossAmount}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="invoiceType">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.invoiceType}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
        <td  mat-cell *matCellDef="let i">
          <span>{{i.invoiceNumber}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
      <tr mat-row *matRowDef="let acc; columns: visibleColumns"></tr>
    </table>
  </div>

  <div class="logs">
    <h3>Latest account activity</h3>
    <til-logs [logsLoader]="logsLoader" [source]="loggingSource" [reference]="account.owner"
              [showSpinningIndicator]="false" [showInputs]="false"></til-logs>
  </div>
</div>
