<div *ngIf="billingAddress" class="show-billing-address-container">
  <div class="labels">
    <div>Company:</div>
    <div>Name:</div>
    <div>Street:</div>
    <div *ngIf="billingAddress.addInfo"></div>
    <div>City:</div>
    <div *ngIf="billingAddress.country"></div>
    <div *ngIf="billingAddress.vatId">Vat Id.:</div>
  </div>
  <div class="values">
    <div>{{billingAddress.company || 'n/a'}}</div>
    <div>{{billingAddress.salutation || ''}} {{billingAddress.degree || ''}} {{billingAddress.lastName || 'n/a'}} {{billingAddress.firstName || ''}}</div>
    <div>{{billingAddress.street}} {{billingAddress.streetNumber || ''}}</div>
    <div *ngIf="billingAddress.addInfo">{{billingAddress.addInfo}}</div>
    <div>{{billingAddress.zip}} {{billingAddress.city}}</div>
    <div *ngIf="billingAddress.country">{{billingAddress.country}}</div>
    <div *ngIf="billingAddress.vatId"></div>
  </div>
</div>
