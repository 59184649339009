import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import {SharedModule} from '../shared/shared.module';
import {TiFrontendSharedModule} from 'ti-frontend-shared';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptorService} from './services/token-interceptor.service';
import { WelcomeComponent } from './components/welcome/welcome.component';
import {ErrorResponseInterceptorService} from "./services/error-response-interceptor.service";



@NgModule({
  declarations: [LoginComponent, SideNavComponent, WelcomeComponent],
  imports: [
    CommonModule,
    SharedModule,
    TiFrontendSharedModule
  ],
  exports: [
    SideNavComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptorService,
      multi: true
    }
  ]

})
export class CoreModule { }
