import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceTO} from "../../../shared/generated/transportObjects";
import {ManagementService} from "../../services/management.service";
import {InvoicesListComponent} from "../invoices-list/invoices-list.component";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";

@Component({
  selector: 'pa-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  @ViewChild('invoicesListComponent')
  invoicesListComponent: InvoicesListComponent;

  public invoices: InvoiceTO[] = [];
  public loading: boolean;

  constructor(
    private managementService: ManagementService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.managementService.findOpenInvoices().subscribe(res => {
      this.invoices = res;
      this.loading = false;
      this.invoicesListComponent.updateTable();
    }, () => this.loading = false);
  }

  public rebook(invoice: InvoiceTO, notify: boolean) {
    let context: ConfirmationModalContext;
    if (notify) {
      context = {
        headline: 'Book invooice & notify client',
        content: `Would you really like to book invoice no. ${invoice.invoiceNumber}, for ${invoice.owner} with amount of ${invoice.grossAmount}€, and notify ${invoice.owner}?`,
        severity: 'warn'
      };
    } else {
      context = {
        headline: 'Book invooice',
        content: `Would you really like to book invoice no. ${invoice.invoiceNumber}, for ${invoice.owner} with amount of ${invoice.grossAmount}€?`,
        severity: 'warn'
      };
    }
    this.modalService.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.loading = true;
        this.managementService.rebookInvoice(invoice.id, notify).subscribe(
          () => {
            const index = this.invoices.indexOf(invoice);
            this.invoices.splice(index, 1);
            this.invoicesListComponent.updateTable();
            this.loading = false;
          }, () => this.loading = false
        );
      }
    });
  }

}
