import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  VCAddressTO,
  VcSubscriptionInfoTO,
  VolumeCustomerTO
} from "../../generated/transportObjects";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../util/application-helper";

@Component({
  selector: 'pa-edit-create-address-modal',
  templateUrl: './edit-create-address-modal.component.html',
  styleUrls: ['./edit-create-address-modal.component.scss']
})
export class EditCreateAddressModalComponent implements OnInit {

  public readonly editMode: boolean;
  address: VCAddressTO;

  constructor(
    public dialogRef: MatDialogRef<EditCreateAddressModalComponent, VCAddressTO>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(data);
    this.address = data;
  }

  ngOnInit(): void {
  }



}
