import {Component, Input, OnInit} from '@angular/core';
import {VCAddressTO} from "../../generated/transportObjects";

@Component({
  selector: 'pa-show-address',
  templateUrl: './show-address.component.html',
  styleUrls: ['./show-address.component.scss']
})
export class ShowAddressComponent implements OnInit {

  @Input()
  address: VCAddressTO;

  constructor() { }

  ngOnInit(): void {
  }

}
