import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {VolumeCustomersModule} from "./volume-customers/volume-customers.module";
import {ReportsModule} from "./reports/reports.module";
import {PaymentModule} from "./payment/payment.module";
import {AccountsModule} from "./accounts/accounts.module";
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from "@angular/common";

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    VolumeCustomersModule,
    ReportsModule,
    PaymentModule,
    AccountsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
