import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  VcSubscriptionInfoTO, VolumeCustomerTO
} from "../../../shared/generated/transportObjects";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../../shared/util/application-helper";

@Component({
  selector: 'pa-edit-create-subscription-info-modal',
  templateUrl: './edit-create-subscription-info-modal.component.html',
  styleUrls: ['./edit-create-subscription-info-modal.component.scss']
})
export class EditCreateSubscriptionInfoModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public subscriptionInfoTO: VcSubscriptionInfoTO;
  public vc: VolumeCustomerTO;
  public readonly subscriptionTypes;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCreateSubscriptionInfoModalComponent, VcSubscriptionInfoTO>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.subscriptionInfoTO = data.si;
    this.vc = data.vc;
    this.editMode = ApplicationHelper.isObjectDefined(data.si);
    this.subscriptionTypes = ApplicationHelper.subscriptionTypes;
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.patchValue(this.subscriptionInfoTO);
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: VcSubscriptionInfoTO = this.formGroup.value;
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    let vu;
    if (this.vc.validUntil) {
      vu = new Date(this.vc.validUntil);
    }
    this.formGroup = this.fb.group({
      validUntil: this.fb.control(vu, Validators.required),
      subscriptionType: this.fb.control(this.vc.subscriptionType, Validators.required),
      pricePerLicence: this.fb.control(this.vc.pricePerLicence, Validators.required),
      invoiceIntervalInMonth: this.fb.control(this.vc.invoiceIntervalInMonth, Validators.required)
    });
  }

}
