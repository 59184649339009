import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import {SharedModule} from "../shared/shared.module";
import { PaymentServiceComponent } from './components/payment-service/payment-service.component';
import { CreditCardsComponent } from './components/credit-cards/credit-cards.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { CreditCardsListComponent } from './components/credit-cards-list/credit-cards-list.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { OpenPaymentsListComponent } from './components/open-payments-list/open-payments-list.component';
import { UpcomingPaymentsListComponent } from './components/upcoming-payments-list/upcoming-payments-list.component';
import {StompRService} from "@stomp/ng2-stompjs";
import { DueVolumeCustomersListComponent } from './components/due-volume-customers-list/due-volume-customers-list.component';


@NgModule({
  declarations: [PaymentServiceComponent, CreditCardsComponent, InvoicesComponent, CreditCardsListComponent, InvoicesListComponent, OpenPaymentsListComponent, UpcomingPaymentsListComponent, DueVolumeCustomersListComponent],
  imports: [
    CommonModule,
    SharedModule,
    PaymentRoutingModule
  ],
  providers: [StompRService]
})
export class PaymentModule { }
