export class ApiEndpoints {
  // base
  private static base = "/polly/admin";

  // Volume Customers
  public static createVC = '/treeit/vs/rest/v1/vc/create';
  public static deleteVC = '/treeit/vs/rest/v1/vc/delete/';
  public static getAllVC = '/treeit/vs/rest/v1/vc/all';
  public static findVC = '/treeit/vs/rest/v1/vc/find/';
  public static renameVC = '/treeit/vs/rest/v1/vc/rename/';
  public static changeInvoicingData = '/treeit/vs/rest/v1/vc/change-invoice-data/';
  public static changeCustomerNote = '/treeit/vs/rest/v1/vc/change-note/';

  public static deleteAllClients = '/treeit/vs/rest/v1/vc/bulk/clients/delete/';
  public static recallAllClients = '/treeit/vs/rest/v1/vc/bulk/clients/recall/';
  public static setAllClientsRunning = '/treeit/vs/rest/v1/vc/bulk/clients/running/';

  public static editVCAddress = '/treeit/vs/rest/v1/vc/address/edit';
  public static editVCBillingAddress = '/treeit/vs/rest/v1/vc/billing/edit';
  public static saveContactPerson = '/treeit/vs/rest/v1/vc/cp/save';
  public static deleteContactPerson = '/treeit/vs/rest/v1/vc/cp/delete';
  public static saveSubscriptionInfo = '/treeit/vs/rest/v1/vc/subscription/save/';
  public static addVolumeCustomerClients = '/treeit/vs/rest/v1/vc/clients/add';
  public static convertNonTestUser = '/treeit/vs/rest/v1/vc/clients/convert/';
  public static switchAdminState = '/treeit/vs/rest/v1/vc/clients/admin/';
  public static activateOrderedClient = '/treeit/vs/rest/v1/vc/clients/activate-ordered/';

  public static getInvoicesForCustomer = '/treeit/vs/rest/v1/vc/invoices/';
  public static createVCInvoice = '/treeit/vs/rest/v1/vc/invoices/create/';
  public static deleteVolumeClient = '/treeit/vs/rest/v1/vc/clients/delete/';
  public static printInvoice = '/treeit/vs/rest/v1/vc/invoices/print/';

  // Business Customer Requests
  public static createBusinessCustomerRequest = '/treeit/vs/rest/v1/vc/business-requests/create';
  public static findAllBusinessCustomerRequests = '/treeit/vs/rest/v1/vc/business-requests/all';
  public static deleteBusinessCustomerRequest = '/treeit/vs/rest/v1/vc/business-requests/';
  public static transferBusinessCustomerRequest = '/treeit/vs/rest/v1/vc/business-requests/transfer/';
  public static findBusinessCustomerRequest = '/treeit/vs/rest/v1/vc/business-requests/find/';
  public static updateBusinessCustomerRequest = '/treeit/vs/rest/v1/vc/business-requests/update/';

  // Metrics Endpoints
  public static metricsRoot = '/treeit/vs/rest/v1/actuator';
  public static metricsHealth = `${ApiEndpoints.metricsRoot}/health`;
  public static metricsInfo = `${ApiEndpoints.metricsRoot}/info`;
  public static metricsDetailsRoot = `${ApiEndpoints.metricsRoot}/metrics/`;

  // Credit Cards
  public static getExpiringCards = '/treeit/vs/rest/v1/management/cards/expiring';
  public static notifyCardHoldingAccount = '/treeit/vs/rest/v1/management/cards/notify';

  // Invoices
  public static findOpenInvoices = '/treeit/vs/rest/v1/management/invoices/open';
  public static rebookInvoice = '/treeit/vs/rest/v1/management/invoices/rebook';

  // Accounts
  public static accountStatistics = '/treeit/vs/rest/v1/management/account-statistics/';
  public static searchAccount = '/treeit/vs/rest/v1/management/account/search/';
  public static accountInfo(accountId: number) {
    return `/treeit/vs/rest/v1/management/account/info/${accountId.toString()}`
  }
  public static deleteAccount(accountId: number) {
    return `/treeit/vs/rest/v1/management/account/${accountId.toString()}`
  }

  // Logs
  public static applicationLogs = '/treeit/admin/monitoring/all';

  // Payments
  public static openPayments = '/treeit/vs/rest/v1/management/payments/open';
  public static upcomingPayments = '/treeit/vs/rest/v1/management/payments/upcoming-payments';
  public static createOpenPaymentsForDueSubscriptions = '/treeit/vs/rest/v1/management/payments/create-payments';
  public static processOpenPayments = '/treeit/vs/rest/v1/management/payments/process';
  public static processOpenPayment = '/treeit/vs/rest/v1/management/payment/process/';
  public static loadDueVolumeCustomers = '/treeit/vs/rest/v1/management/payments/due-volume-customers';
}
