import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { VCAddressTO} from "../../generated/transportObjects";

@Component({
  selector: 'pa-create-edit-address',
  templateUrl: './create-edit-address.component.html',
  styleUrls: ['./create-edit-address.component.scss']
})
export class CreateEditAddressComponent implements OnInit {
  public formGroup: FormGroup;
  @Input()
  address: VCAddressTO;
  @Output()
  onCancelClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSaveClick: EventEmitter<VCAddressTO> = new EventEmitter<VCAddressTO>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.address) {
      this.formGroup.patchValue(this.address);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      city: this.fb.control(undefined, Validators.required),
      zip: this.fb.control(undefined, Validators.required),
      street: this.fb.control(undefined, Validators.required),
      streetNumber: this.fb.control(undefined, Validators.required),
    });
  }

}
