<div *ngIf="invoices && invoices.length > 0; else noinvoices">
  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="owner" headerText="Onwer"></mat-text-column>
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td class="action-column" mat-cell *matCellDef="let i">
        <span>{{i.created | date: "dd.MM.yyyy"}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td class="action-column" mat-cell *matCellDef="let i">
        <span>{{i.invoiceType}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>Period</th>
      <td mat-cell *matCellDef="let i">
      <span *ngIf="i.startPeriod && i.endPeriod">{{i.startPeriod | date: "dd.MM.yyyy"}} - {{i.endPeriod | date: "dd.MM.yyyy"}}</span></td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td class="action-column" mat-cell *matCellDef="let i">
        <span>{{i.grossAmount | currency:'EUR':true}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="action-column" mat-cell *matCellDef="let i">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>archive</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="onBookClicked.emit(i)" mat-menu-item>Book</button>
          <button (click)="onBookAndNotifyClicked.emit(i)" mat-menu-item>Book & Notify</button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let i; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #noinvoices>
  <div class="no-invoices">
    <span class="material-icons">thumb_up</span>
    <div>No open Invoices</div>
  </div>
</ng-template>
