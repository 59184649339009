import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VolumeCustomerService} from '../../services/volume-customers.service';
import {SpinnerService} from 'ti-frontend-shared';
import {ModalService} from '../../../core/services/modal.service';
import {VcSubscriptionInfoTO, VolumeCustomerTO} from '../../../shared/generated/transportObjects';
import {ConfirmationModalContext} from '../../../shared/model/confirmation-modal-context';
import {ShowContactPersonComponent} from '../../../shared/components/show-contact-person/show-contact-person.component';

@Component({
  selector: 'pa-volume-customer-details',
  templateUrl: './volume-customer-details.component.html',
  styleUrls: ['./volume-customer-details.component.scss']
})
export class VolumeCustomerDetailsComponent implements OnInit {
  public volumeCustomer: VolumeCustomerTO;

  @ViewChild('showContacts', {static: false})
  showContacts: ShowContactPersonComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private volumeCustomerservice: VolumeCustomerService,
    private spinner: SpinnerService,
    private ms: ModalService,
    private router: Router,
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    const id: number = this.activatedRoute.snapshot.params.id;
    this.volumeCustomerservice.findVC(id).subscribe(value => {
      this.volumeCustomer = value;
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }


  openSubscriptionInfoModal(edit: boolean) {
    let si: VcSubscriptionInfoTO;
    if (edit) {
      si = {
        invoiceIntervalInMonth: this.volumeCustomer.invoiceIntervalInMonth,
        pricePerLicence: this.volumeCustomer.pricePerLicence,
        subscriptionType: this.volumeCustomer.subscriptionType,
        validUntil: new Date(this.volumeCustomer.validUntil)
      };
    }
    this.ms.opensubscriptionInfoModal(this.volumeCustomer, si).afterClosed().subscribe(value => {
      if (value) {
        this.volumeCustomerservice.saveSubscriptionInfo(this.volumeCustomer.id, value).subscribe(value1 => {
          this.volumeCustomer = value1;
        });
      }
    });
  }

  openBillingAddressModal(edit: boolean) {
    this.ms.openBillingAddressModal(edit ? this.volumeCustomer.billingAddress : undefined).afterClosed().subscribe(value => {
      if (value) {
        this.volumeCustomerservice.editBillingAddress(this.volumeCustomer.id, value).subscribe(value1 => {
          this.volumeCustomer.billingAddress = value1;
        });
      }
    });
  }

  openAddressModal(edit: boolean) {
    this.ms.openAddressModal(edit ? this.volumeCustomer.address : undefined).afterClosed().subscribe(value => {
      if (value) {
        this.volumeCustomerservice.editAddress(this.volumeCustomer.id, value).subscribe(value1 => {
          this.volumeCustomer.address = value1;
        });
      }
    });
  }

  openDeleteVolumeCustomerModal() {
    const context: ConfirmationModalContext = {
      headline: 'Delete Volume Customer',
      content: 'Would you really like to delete this volume customer ?',
      severity: 'warn'
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.volumeCustomerservice.deleteVC(this.volumeCustomer.id).subscribe(value1 => {
          this.router.navigateByUrl('volume-customers/all');
        });
      }
    });
  }

  openEditVolumeCustomerModal() {
    this.ms.openCreateEditVolumeCustomer(this.volumeCustomer).afterClosed().subscribe(value => {
      if (value) {
        this.volumeCustomerservice.renameVC(value.id, value.customerName).subscribe(value1 => {
          this.volumeCustomer.customerName = value1;
        });
      }
    });
  }

  openNotesModal(edit: boolean) {
    this.ms.openNotesModal(edit ? this.volumeCustomer.notes : undefined).afterClosed().subscribe(value => {
      this.volumeCustomerservice.changeCustomerNote(this.volumeCustomer.id, value).subscribe(value1 => {
        this.volumeCustomer = value1;
      });
    });
  }

  openContactModal(edit: boolean) {
    this.ms.openContactPersonModal().afterClosed().subscribe(value => {
      if (value) {
      this.volumeCustomerservice.saveContactPerson(this.volumeCustomer.id, value).subscribe(value1 => {
        this.volumeCustomer.contactPeople = value1;
        this.showContacts.updateTable();
      });
      }
    });

  }
}

