import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {VolumeCustomerTO} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-due-volume-customers-list',
  templateUrl: './due-volume-customers-list.component.html',
  styleUrls: ['./due-volume-customers-list.component.scss']
})
export class DueVolumeCustomersListComponent implements OnInit, AfterViewInit {

  @Input()
  dueVolumeCustomers: VolumeCustomerTO[] = [];

  @Input()
  public visibleColumns: string[] = ["customerName", "customerId", "validUntil", "licences"];

  @Output()
  onVcClicked: EventEmitter<VolumeCustomerTO> = new EventEmitter<VolumeCustomerTO>();

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<VolumeCustomerTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<VolumeCustomerTO>(this.dueVolumeCustomers);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<VolumeCustomerTO>(this.dueVolumeCustomers);
      this.tableDataSource.paginator = this.paginator;
    });
  }
}


