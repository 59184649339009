import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {
  OpenPaymentInfoTO, OpenPaymentTO,
  UpcomingPaymentTO,
  VolumeCustomerTO
} from "../../../shared/generated/transportObjects";
import {ManagementService} from "../../services/management.service";
import {ModalService} from "../../../core/services/modal.service";
import {WebsocketService} from "../../services/web-socket.service";
import * as moment from "moment";
import {Moment} from "moment";
import {SpinnerService} from "ti-frontend-shared";
import {Message} from "@stomp/stompjs";
import {CreditCardsListComponent} from "../credit-cards-list/credit-cards-list.component";
import {OpenPaymentsListComponent} from "../open-payments-list/open-payments-list.component";
import {UpcomingPaymentsListComponent} from "../upcoming-payments-list/upcoming-payments-list.component";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {DueVolumeCustomersListComponent} from "../due-volume-customers-list/due-volume-customers-list.component";
import {Router} from "@angular/router";

@Component({
  selector: 'pa-payment-service',
  templateUrl: './payment-service.component.html',
  styleUrls: ['./payment-service.component.scss']
})
export class PaymentServiceComponent implements OnInit {

  @ViewChild('openPaymentsList')
  openPaymentsList: OpenPaymentsListComponent;

  @ViewChild('upcomingPaymentsList')
  upcomingPaymentsList: UpcomingPaymentsListComponent;

  @ViewChild('dueVolumeCustomersList')
  dueVolumeCustomersList: DueVolumeCustomersListComponent;

  public openPaymentInfo: OpenPaymentInfoTO;
  public openPayments: OpenPaymentTO[] = [];
  public upcomingPayments: UpcomingPaymentTO[] = [];
  public volumeCustomers: VolumeCustomerTO[] = [];

  public processing: boolean;
  public processingFinished: boolean;
  public processContainer: ProcessContainer;

  constructor(private wss: WebsocketService,
              private managementService: ManagementService,
              private modalService: ModalService,
              private ms: ModalService,
              private loading: SpinnerService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.wss.getPaymentServiceSubscription().subscribe(message => this.evaluateMessage(message));
    this.managementService.loadOpenPayments().subscribe(res => {
      this.openPaymentInfo = res;
      this.openPayments = res.payments;
      this.openPaymentsList.updateTable();
    });
    this.managementService.loadUpcomingPayments().subscribe(res => {
      this.upcomingPayments = res;
      this.upcomingPayments.sort((a, b) => {
        const aDate = moment(a.nextPaymentDate);
        const bDate = moment(b.nextPaymentDate);
        return aDate.valueOf() - bDate.valueOf();
      });
      this.upcomingPaymentsList.updateTable();
    });
    this.managementService.loadDueVolumeCustomers().subscribe(res => {
      this.volumeCustomers = res;
      this.volumeCustomers.sort((a, b) => {
        const aDate = moment(a.validUntil);
        const bDate = moment(b.validUntil);
        return aDate.valueOf() - bDate.valueOf();
      });
      this.dueVolumeCustomersList.updateTable();
    });
  }

  public createPayments(): void {
    const context: ConfirmationModalContext = {
      headline: "Create  Payments",
      content: "Would you really like to create payments? Activate all scheduled subscriptions and create all open payments?",
      severity: "warn",
      approveButtonLabel: 'yes',
      declineButtonLabel: 'no'
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      this.loading.spin(true);
      if (value) {
        this.managementService.createOpenPaymentsForDueAccounts().subscribe(() => {
          this.managementService.loadOpenPayments().subscribe(res => {
            this.openPaymentInfo = res;
            this.openPayments = this.openPaymentInfo.payments;
            this.refreshTables();
          });
          const today: Moment = moment();
          localStorage.setItem('processedPayments', today.toISOString());
          this.loading.spin(false);
        }, () => this.loading.spin(false), () => this.refreshTables());
      }
      this.loading.spin(false);
    });
  }

  private evaluateMessage(message: Message) {
    if (message.body === 'FINISHED') {
      this.processingFinished = true;
    } else {
      const tmp: ProcessContainer = JSON.parse(message.body);
      this.processContainer = tmp;
    }
  }

  public processPayments(): void {
    const context: ConfirmationModalContext = {
      headline: "Process all payments",
      content: "Would you really like to process all open payments?",
      severity: "warn",
      approveButtonLabel: 'yes',
      declineButtonLabel: 'no'
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.processContainer = {processedAmount: 0, processedCount: 0};
        // call server
        this.processing = true;
        this.managementService.processOpenPayments().subscribe();
      }
    });
  }

  public processSinglePayment(openPayment: OpenPaymentTO): void {
    const context: ConfirmationModalContext = {
      headline: "Process single payment",
      content: `Would you really like to process the payment of ${openPayment.owner} with a net amount of ${openPayment.netAmount} and tax of ${openPayment.taxInPercent}% ?`,
      severity: "warn",
      approveButtonLabel: 'yes',
      declineButtonLabel: 'no'
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
            this.loading.spin(true);
            this.managementService.processSinglePayment(openPayment.id).subscribe(() => {
            this.managementService.loadOpenPayments().subscribe(res => {
            this.openPaymentInfo = res;
            this.openPayments = this.openPaymentInfo.payments;
            this.loading.spin(false);
          }, () => this.loading.spin(false), () => this.refreshTables());
        }, () => this.loading.spin(false));
      }
    });
  }

  public finishProcessing(): void {
    this.managementService.loadOpenPayments().subscribe(res => {
      this.openPaymentInfo = res;
      this.openPayments = this.openPaymentInfo.payments;
      this.processingFinished = false;
      this.processing = false;
    }, () => {
      this.processingFinished = false;
      this.processing = false;
    }, () => this.refreshTables());
  }

  goToVcDetails(vc: VolumeCustomerTO) {
    const context: ConfirmationModalContext = {
      headline: "Leave payments view",
      content: `Would you really like to detailsview of ${vc.customerName}?`,
      severity: "warn",
      approveButtonLabel: 'yes',
      declineButtonLabel: 'no'
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.router.navigateByUrl('volume-customers/' + vc.id + '/details');
      }
    });
  }

  private refreshTables() {
    if (this.dueVolumeCustomersList) {
      this.dueVolumeCustomersList.updateTable();
    }
    if (this.upcomingPaymentsList) {
      this.upcomingPaymentsList.updateTable();
    }
    if (this.openPaymentsList) {
      this.openPaymentsList.updateTable();
    }
  }


}

export interface ProcessContainer {
  processedAmount: number;
  processedCount: number;
}
