<div *ngIf="openPayments && openPayments.length > 0; else noOpenPayments">
  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="owner" headerText="Account Owner"></mat-text-column>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td class="action-column" mat-cell *matCellDef="let op">
        <span>{{op.netAmount | currency:'EUR':true}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>Period</th>
      <td class="action-column" mat-cell *matCellDef="let op">
        <span>{{op.periodStart | date:"dd.MM.yyyy"}} - {{op.periodEnd | date:"dd.MM.yyyy"}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td class="action-column" mat-cell *matCellDef="let op">
        <span>{{op.invoiceType === 'UPGRADE' ? 'Upgrade' : 'Recurring'}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="errors">
      <th mat-header-cell *matHeaderCellDef>Errors</th>
      <td class="action-column" mat-cell *matCellDef="let op">
        <span *ngIf="op.errorReason"><mat-icon [matTooltip]="op.errorReason">dangerous</mat-icon></span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="reverse-charge">
      <th mat-header-cell *matHeaderCellDef>Reverse Charge</th>
      <td class="action-column" mat-cell *matCellDef="let op">
        <span *ngIf="op.taxInPercent !== 19"><mat-icon>check</mat-icon></span>
        <span *ngIf="op.taxInPercent === 19"><mat-icon>close</mat-icon></span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr class="selectable-row" (click)="onOpenPaymentClicked.emit(op)" mat-row *matRowDef="let op; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #noOpenPayments>
  <div class="no-open-payments">
    <span class="material-icons">credit_card_off</span>
    <div>No open payments</div>
  </div>
</ng-template>
