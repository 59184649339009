<div class="business-customer-request-overview-container">
  <div class="header">
    <h1>Business Customer Requests</h1>
    <div class="flex-spacer"></div>
    <div (click)="createBusinessCustomerRequest()" class="create-bcr-button">
      <button mat-fab><mat-icon>add</mat-icon></button>
    </div>
  </div>
  <div *ngIf="businessCustomerRequests">
    <pa-business-customer-request-list [businessCustomerRequests]="businessCustomerRequests" (onRequestSelect)="goToDetails($event)"></pa-business-customer-request-list>
  </div>
</div>
