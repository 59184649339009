import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService, SideNavService, SpinnerService} from 'ti-frontend-shared';

@Component({
  selector: 'pa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'admin-frontend';

  public loading = false;
  public sideNavOpen = true;
  private subscriptions: Subscription[] = [];

  public readonly isAdmin: boolean;

  ngOnInit(): void {
    this.subscriptions.push(this.spinnerService.observable().subscribe((value: boolean) => this.loading = value));
    this.subscriptions.push(this.sideNavService.stateObservable.subscribe((value: boolean) => this.sideNavOpen = value));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private sideNavService: SideNavService,
    private authenticationService: AuthenticationService
  ) {
  }

  public get preLogin(): boolean {
    return this.router.url.startsWith('/login') || !this.authenticationService.isAuthenticated();
  }

  public get fullUserName(): string {
    return this.authenticationService.currentUser ? (this.authenticationService.currentUser.firstName || '') + ' ' + (this.authenticationService.currentUser.lastName || '') : '';
  }

  public logout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }


}

