<div class="invoices-container">
  <div class="header">
    <div>
      <h3>Manage invoices</h3>
    </div>
    <div class="flex-spacer"></div>
    <div class="stats-overview" *ngIf="invoices.length > 0">
      <div class="stat single">
        <div class="value">
          <span> {{sales | currency: 'EUR'}}</span>
        </div>
        <div class="label">
          Sum
        </div>
      </div>
    </div>
    <div>
      <button mat-fab (click)="openCreateInvoiceModal()" [disabled]="!volumeCustomer.billingAddress">
        <mat-icon matTooltip="To create a invoice the volume customer has to have a billing address!">add</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="invoices && invoices.length > 0; else noInvoices">
    <table class="invoices-table" mat-table [dataSource]="tableDataSource">
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let invoice">
          <span>{{invoice.created |date: 'dd.MM.yyyy'}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="invoiceNumber">
        <th mat-header-cell *matHeaderCellDef>Invoice-Number</th>
        <td mat-cell *matCellDef="let invoice">
          <span>{{invoice.invoiceNumberString}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="netAmount">
        <th mat-header-cell *matHeaderCellDef>Net amount</th>
        <td mat-cell *matCellDef="let invoice">
          <span>{{invoice.net | currency: 'EUR'}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="action-column" mat-cell *matCellDef="let invoice">
          <button mat-button (click)="printInvoice(invoice)">
            <mat-icon>print</mat-icon>
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
      <tr mat-row *matRowDef="let invoice; columns: visibleColumns"></tr>
    </table>
  </div>
  <ng-template #noInvoices>
    <div class="no-invoices">
      <div class="material-icons">request_page</div>
      <div>No invoices yet</div>
    </div>
  </ng-template>
</div>
