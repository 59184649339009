import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiEndpoints} from "../../shared/util/api-endpoints";

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(
    @Inject('VOTING_SERVER_URL') private serverUrl: string,
    private http: HttpClient
  ) {
  }

  public getHealthInformation() {
    return this.http.get(this.serverUrl + ApiEndpoints.metricsHealth);
  }

  public getSystemInformation() {
    return this.http.get(this.serverUrl + ApiEndpoints.metricsInfo);
  }

  public getSystemDetails(endpoint: string) {
    return this.http.get(this.serverUrl + ApiEndpoints.metricsDetailsRoot + endpoint);
  }


}
