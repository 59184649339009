import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationModalContext} from "../../model/confirmation-modal-context";

@Component({
  selector: 'pa-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalContext
  ) {
    if (typeof this.data.content === "string") {
      this.modalContent.push(this.data.content);
    } else {
      this.modalContent = this.data.content;
    }
  }

  public readonly modalContent: string[] = [];

  ngOnInit(): void {
  }

}
