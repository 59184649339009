<div *ngIf="businessCustomerRequests && businessCustomerRequests.length > 0; else nobcr">

  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="companyName" headerText="Company/University"></mat-text-column>
    <mat-text-column name="contactFirstName" headerText="Firstname"></mat-text-column>
    <mat-text-column name="contactLastName" headerText="Lastname"></mat-text-column>
    <mat-text-column name="contactMailAddress" headerText="Contact-Mail"></mat-text-column>
    <mat-text-column name="amountLicences" headerText="Licenses"></mat-text-column>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td class="action-column" mat-cell *matCellDef="let bcr">
        <span>{{getBCRStatus(bcr)}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr class="selectable-row" (click)="onRequestSelect.emit(bcr.id)" mat-row *matRowDef="let bcr; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #nobcr>
  <div class="no-requests">
    <span class="material-icons">supervisor_account</span>
    <div>No business customer requests currently</div>
  </div>
</ng-template>
