<div *ngIf="preLogin else loggedIn">
  <router-outlet></router-outlet>
</div>
<ng-template #loggedIn>
<div class="application-content">
  <mat-toolbar>
    <span class="headline">Polly Admin</span>
    <button routerLink="/accounts/overview" mat-button>Accounts</button>
    <button routerLink="/volume-customers/all" mat-button>Volume Customers</button>
    <button routerLink="/payment/payment-service" mat-button>Payment</button>
    <button routerLink="reports/logs" mat-button>Reports</button>
    <div class="spacer"></div>
    <div>{{fullUserName}}</div>
    <div (click)="logout()" class="logout">
      <span class="material-icons">directions_run</span>
    </div>
  </mat-toolbar>
  <mat-sidenav-container hasBackdrop="false">
    <mat-sidenav mode="side" [opened]="sideNavOpen">
      <pa-side-nav></pa-side-nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="route-content-container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
</ng-template>
<til-spinner *ngIf="loading"></til-spinner>
