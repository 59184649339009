<form novalidate [formGroup]="formGroup">
  <div class="two fields">
    <mat-form-field>
      <mat-label>Street*</mat-label>
      <input #street matInput formControlName="street" name="street" type="text" maxlength="100"/>
      <mat-hint align="end">{{ street.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Street No.*</mat-label>
      <input #streetNumber matInput formControlName="streetNumber" name="streetNumber" type="text" maxlength="100"/>
      <mat-hint align="end">{{ streetNumber.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>ZIP*</mat-label>
      <input #zip  matInput formControlName="zip" name="zip" type="text" maxlength="5" minlength="5"/>
      <mat-hint align="end">{{ zip.value?.length || 0 }}/5</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>City*</mat-label>
      <input #city matInput formControlName="city" name="city" type="text" maxlength="100"/>
      <mat-hint align="end">{{ city.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
</form>
<div class="ti actions">
  <button mat-button (click)="onCancelClick.emit()">Cancel</button>
  <button [disabled]="formGroup.invalid" (click)="onSaveClick.emit(formGroup.value)" mat-flat-button color="accent">Save</button>
</div>
