import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'pa-add-licence-modal',
  templateUrl: './add-licence-modal.component.html',
  styleUrls: ['./add-licence-modal.component.scss']
})
export class AddLicenceModalComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddLicenceModalComponent, string>
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: string = this.formGroup.controls.clientId.value;
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      clientId: this.fb.control(undefined, [Validators.required, Validators.email]),
    });
  }

}
