import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {VolumeLicenceInvoiceTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'pa-create-vc-invoice',
  templateUrl: './create-vc-invoice-modal.component.html',
  styleUrls: ['./create-vc-invoice-modal.component.scss']
})
export class CreateVcInvoiceModalComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateVcInvoiceModalComponent, VolumeLicenceInvoiceTO>
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: VolumeLicenceInvoiceTO = this.formGroup.value;
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      amountLicences: this.fb.control('', Validators.required),
      pricePerLicence: this.fb.control('', Validators.required),
      positionDescription: this.fb.control('', Validators.required),
      tax: this.fb.control(19, Validators.required)
    });
  }

}
