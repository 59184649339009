import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {AccountsService} from "../../services/accounts.service";
import {AccountOverviewTO, SearchTO} from "../../../shared/generated/transportObjects";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {Page, SpinnerService} from "ti-frontend-shared";
import {Router} from "@angular/router";
import {ModalService} from "../../../core/services/modal.service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'pa-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {

  accounts: AccountOverviewTO[] = [];
  page: Page<AccountOverviewTO>;
  formGroup: FormGroup;
  public visibleColumns: string[] = ["owner", "created", "vc", "lastActiveSubscription", "lastLogin", "canceled"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<AccountOverviewTO>;

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<AccountOverviewTO>(this.page.content);
      if (this.paginator) {
        this.paginator.pageIndex = this.page.number;
        this.paginator.pageSize = this.page.size;
      }
    });
  }


  constructor(private accountService: AccountsService,
              private formBuilder: FormBuilder,
              private spinner: SpinnerService,
              private router: Router,
              private modalService: ModalService) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name: this.formBuilder.control(undefined),
      page: this.formBuilder.control(undefined),
      size: this.formBuilder.control(undefined)
    });
    this.tableDataSource = new MatTableDataSource<AccountOverviewTO>(this.accounts);
    this.accountService.searchAccount({name: '', page: 0, size: 25}).subscribe(value => {
      this.page = value;
      this.accounts = this.page.content;
      this.updateTable();
    });
  }

  private loadPage(page: number, size: number): void {
    this.spinner.spin(true);
    const detailSearch: SearchTO = this.formGroup.value;
    detailSearch.page = page;
    detailSearch.size = size;
    this.accountService.searchAccount(detailSearch).subscribe(
      (res: Page<AccountOverviewTO>) => {
        this.page = res;
        this.accounts = this.page.content;
        this.updateTable();
        this.spinner.spin(false);
      },
      () => this.spinner.spin(false)
    );
  }

  public processPageEvent(pageEvent: PageEvent) {
    if (pageEvent.pageIndex !== this.page.number || pageEvent.pageSize !== this.page.size) {
      this.loadPage(pageEvent.pageIndex, pageEvent.pageSize);
    }
  }

  search() {
    this.spinner.spin(true);
    const detailSearch: SearchTO = this.formGroup.value;
    detailSearch.page = 0;
    detailSearch.size = 25;
    this.accountService.searchAccount(detailSearch).subscribe(
      (res: Page<AccountOverviewTO>) => {
        this.page = res;
        this.accounts = this.page.content;
        this.updateTable();
        this.spinner.spin(false);
      },
      () => this.spinner.spin(false)
    );
  }

  goToDetails(id: number) {
    this.router.navigateByUrl('volume-customers/' + id + '/details');
  }
}
