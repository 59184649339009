import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'pa-add-multiple-licences-modal',
  templateUrl: './add-multiple-licences-modal.component.html',
  styleUrls: ['./add-multiple-licences-modal.component.scss']
})
export class AddMultipleLicencesModalComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddMultipleLicencesModalComponent, string[]>
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: string[] = this.formGroup.controls.clientIds.value.split(this.formGroup.controls.delimiter.value);
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      delimiter: this.fb.control(',', Validators.required),
      clientIds: this.fb.control(undefined, Validators.required),
    });
  }

}
