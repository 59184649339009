import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {UpcomingPaymentTO} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-upcoming-payments-list',
  templateUrl: './upcoming-payments-list.component.html',
  styleUrls: ['./upcoming-payments-list.component.scss']
})
export class UpcomingPaymentsListComponent implements OnInit, AfterViewInit {

  @Input()
  upcomingPayments: UpcomingPaymentTO[] = [];

  @Input()
  public visibleColumns: string[] = ["accountOwner", "amount", "nextPaymentDate", "vc"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<UpcomingPaymentTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<UpcomingPaymentTO>(this.upcomingPayments);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<UpcomingPaymentTO>(this.upcomingPayments);
      this.tableDataSource.paginator = this.paginator;
    });
  }
}

