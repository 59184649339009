<div *ngIf="cards && cards.length >= 0; else noCards">
  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="cardType" headerText="Card-Type"></mat-text-column>
    <mat-text-column name="cardHolder" headerText="Card-Holder"></mat-text-column>
    <ng-container matColumnDef="expires">
      <th mat-header-cell *matHeaderCellDef>Expires</th>
      <td class="action-column" mat-cell *matCellDef="let card">
      <span>{{card.expireMonth}}/{{card.expireYear}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="nextBillingDate">
      <th mat-header-cell *matHeaderCellDef>Next billing date</th>
      <td class="action-column" mat-cell *matCellDef="let card">
        <span>{{card.nextBillingDate| date:"dd.MM.yyyy"}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="nextBillingAmount">
      <th mat-header-cell *matHeaderCellDef>Next billing ammount</th>
      <td class="action-column" mat-cell *matCellDef="let card">
        <span>{{card.nextBillingAmount | currency:'EUR':true}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="notifications">
      <th mat-header-cell *matHeaderCellDef>Notifications</th>
      <td class="action-column" mat-cell *matCellDef="let card">
        <div *ngFor="let note of card.notifications">{{note.notified | date:"dd.MM.yyyy - HH:mm:ss"}}</div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="action-column" mat-cell *matCellDef="let card">
        <a (click)="onNotifyClicked.emit(card)">Notify</a>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let bcr; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #noCards>
  <div class="no-cards">
    <span class="material-icons">credit_card_off</span>
    <div>No card informations loaded</div>
  </div>
</ng-template>
