import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {APP_CONFIG, AppConfig} from "./app.config";

fetch('/assets/context/context.json')
.then((response) => response.json())
.then((config: AppConfig) => {
  if (config.production) {
    enableProdMode();
  }

  platformBrowserDynamic([{provide: APP_CONFIG, useValue: config},
    {
      provide: 'ADM_SERVER_URL',
      useValue: config.adminServerUrl
    },
    {
      provide: 'VOTING_SERVER_URL',
      useValue: config.votingServerUrl
    },
    {
      provide: 'POLLY_CLIENT_URL',
      useValue: config.votingClientUrl
    },
    {
      provide: 'WS_SERVER_URL',
      useValue: config.wsConnectionURL
    },
    {
      provide: 'ENV_NAME',
      useValue: config.name
    }])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
});
