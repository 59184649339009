<div class="accounts-overview-container">
  <div class="header">
    <h1>Overview</h1>
  </div>
  <div class="chart" *ngIf="data">
    <google-chart [type]="'PieChart'" [data]="categoryData"
                  [options]="categoryChartOptions">
    </google-chart>
  </div>
  <div>
    <div class="stats-overview" *ngIf="data">
      <div class="stat single">
        <div class="value">
          <span> {{data.totalAccounts}}</span>
        </div>
        <div class="label">
          Total Accounts
        </div>
      </div>
    </div>
  </div>
  <div class="divide">
    <mat-divider></mat-divider>
  </div>
  <div>
    <div class="stats-overview" *ngIf="data">
      <div class="stat">
        <div class="value">
          <span> {{data.basicMonth}}</span>
        </div>
        <div class="label">
          Basic Monthly
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span>{{data.basicYear}}</span>
        </div>
        <div class="label">
          Basic Anual
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span> {{data.premiumMonth}}</span>
        </div>
        <div class="label">
          Premium Monthly
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span>{{data.premiumYear}}</span>
        </div>
        <div class="label">
          Premium Anual
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span> {{data.testAccounts}}</span>
        </div>
        <div class="label">
          Test Accounts
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span>{{data.vc}}</span>
        </div>
        <div class="label">
          VC Accounts
        </div>
      </div>
      <div class="stat">
        <div class="value">
          <span>{{data.polls}}</span>
        </div>
        <div class="label">
          Polls
        </div>
      </div>
          <div class="stat">
                <div class="value">
                      <span>{{data.questions}}</span>
                </div>
                <div class="label">
                      Questions
                </div>
          </div>
    </div>
  </div>
      <div class="chart" *ngIf="data">
            <google-chart [type]="'PieChart'" [data]="pollsData"
                          [options]="pollsChartOptions">
            </google-chart>
      </div>
      <div class="chart" *ngIf="data">
            <google-chart [type]="'PieChart'" [data]="questionData"
                          [options]="questionsChartOptions">
            </google-chart>
      </div>
      <div class="chart" *ngIf="data">

            <google-chart [type]="'ColumnChart'" [data]="newAccountsChartData"
                          [columns]="['Month', 'Accounts']" [options]="newAccountsChartOptions">
            </google-chart>
      </div>

</div>





