import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';

@Component({
  selector: 'pa-details-sub-section',
  templateUrl: './details-sub-section.component.html',
  styleUrls: ['./details-sub-section.component.scss']
})
export class DetailsSubSectionComponent implements OnInit {
  @Input()
  headline;
  @Input()
  icon;
  @Input()
  isDataDefined;
  @Input()
  displayButton = true;
  @Input()
  noContentMessage;
  @Output()
  onButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void {
  }

}
