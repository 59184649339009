export class ApplicationHelper {

  public static subscriptionTypes = [
    {label: "Test", value: "TEST"},
    {label: "Basic", value: "BASIC"},
    {label: "Premium", value: "PREMIUM"},
    {label: "Business", value: "BUSINESS"}
  ];

  public static salutations = [
    {label: "Frau", value: "Frau"},
    {label: "Herr", value: "Herr"},
  ];

  public static customerTypes = [
    {label: 'Privatkunde', value: 'PRIVATE'},
    {label: 'Geschäftskunde', value: 'BUSINESS'},
  ];

   public static isObjectDefined(anyObject: any): boolean {
      return anyObject !== null && anyObject !== undefined;
   }
}
