import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import * as moment from "moment";
import {Moment} from "moment";
import {BusinessCustomerRequestTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'pa-business-customer-request-list',
  templateUrl: './business-customer-request-list.component.html',
  styleUrls: ['./business-customer-request-list.component.scss']
})
export class BusinessCustomerRequestListComponent implements OnInit, AfterViewInit {

  @Input()
  businessCustomerRequests: BusinessCustomerRequestTO[] = [];

  @Output()
  onRequestSelect: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public visibleColumns: string[] = ["companyName", "contactFirstName", "contactLastName", "contactMailAddress", "amountLicences", "status"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<BusinessCustomerRequestTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<BusinessCustomerRequestTO>(this.businessCustomerRequests);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<BusinessCustomerRequestTO>(this.businessCustomerRequests);
      this.tableDataSource.paginator = this.paginator;
    });
  }

  public getBCRStatus(bcr: BusinessCustomerRequestTO): string {
    if (!bcr.approved) {
      const expiring: Moment = moment(bcr.expiring);
      return expiring.isBefore(moment()) ? 'Expired' : 'Created';
    } else {
      return 'Approved';
    }
  }
}

