<div class="details-sub-container">
  <div class="section-headline-container">
    <h3>{{headline}}</h3>
    <button *ngIf="displayButton" (click)="onButtonClicked.emit(isDataDefined)" mat-mini-fab>
      <mat-icon>{{icon ? icon : isDataDefined ? 'edit' : 'add'}}</mat-icon>
    </button>
  </div>
  <div *ngIf="isDataDefined; else noData">
    <ng-content></ng-content>
  </div>
  <ng-template #noData>
    <div class="no-content-container">
      <div class="material-icons">warning</div>
      <div>{{noContentMessage}}</div>
    </div>
  </ng-template>
</div>
