import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContactPersonTO, VCAddressTO, VolumeClientTO} from "../../generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ModalService} from "../../../core/services/modal.service";
import {VolumeCustomerService} from "../../../volume-customers/services/volume-customers.service";
import {DatePipe} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationModalContext} from "../../model/confirmation-modal-context";

@Component({
  selector: 'pa-show-contact-person',
  templateUrl: './show-contact-person.component.html',
  styleUrls: ['./show-contact-person.component.scss']
})
export class ShowContactPersonComponent implements OnInit, AfterViewInit {


  @Input()
  contacts: ContactPersonTO[];

  @Input()
  public visibleColumns: string[] = ["name", "email", "phone", "actions"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public tableDataSource: MatTableDataSource<ContactPersonTO>;
  readonly vcId;

  constructor(private ms: ModalService, private licenceService: VolumeCustomerService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
    this.vcId = this.activatedRoute.snapshot.params["id"];
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<ContactPersonTO>(this.contacts);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<ContactPersonTO>(this.contacts);
      this.tableDataSource.paginator = this.paginator;
    });
  }


  openEditModal(contact: ContactPersonTO) {
    this.ms.openContactPersonModal(contact).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.saveContactPerson( this.vcId, value).subscribe(value1 => {
          this.contacts = value1;
          this.updateTable();
        });
      }
    });
  }

  openDeleteModal(contactPersonTO: ContactPersonTO) {
    const context: ConfirmationModalContext = {
      headline: "Delete contact person",
      content: "Would you really like to delete " + contactPersonTO.firstName + ' ' + contactPersonTO.lastName + "?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.deleteContactPerson(contactPersonTO.id, this.vcId).subscribe(value1 => {
          this.contacts = value1;
          this.updateTable();
        });
      }
    });
  }
}

