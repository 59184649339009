import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {VolumeCustomerTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'pa-volume-customer-list',
  templateUrl: './volume-customer-list.component.html',
  styleUrls: ['./volume-customer-list.component.scss']
})
export class VolumeCustomerListComponent implements OnInit, AfterViewInit {

  @Input()
  volumeCustomers: VolumeCustomerTO[] = [];

  @Output()
  onVolumeCustomerSelect: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public visibleColumns: string[] = ["customerName", "customerNumber", "numberOfLicenses", "validUntil"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<VolumeCustomerTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<VolumeCustomerTO>(this.volumeCustomers);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<VolumeCustomerTO>(this.volumeCustomers);
      this.tableDataSource.paginator = this.paginator;
    });
  }
}
