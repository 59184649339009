import {Component, OnInit, ViewChild} from '@angular/core';
import {SpinnerService} from "ti-frontend-shared";
import {VolumeCustomerService} from "../../services/volume-customers.service";
import {ModalService} from "../../../core/services/modal.service";
import {BusinessCustomerRequestListComponent} from "../business-customer-request-list/business-customer-request-list.component";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {BusinessCustomerRequestTO} from "../../../shared/generated/transportObjects";
import {Router} from "@angular/router";

@Component({
  selector: 'pa-business-customer-request-overview',
  templateUrl: './business-customer-request-overview.component.html',
  styleUrls: ['./business-customer-request-overview.component.scss']
})
export class BusinessCustomerRequestOverviewComponent implements OnInit {
  businessCustomerRequests: BusinessCustomerRequestTO[];
  @ViewChild(BusinessCustomerRequestListComponent)
  public businessCustomerRequestList: BusinessCustomerRequestListComponent;

  constructor(private spinnerService: SpinnerService,
              private businessCustomerService: VolumeCustomerService,
              private modalService: ModalService,
              private router: Router) {
    businessCustomerService.findAllBusinessCustomerRequests().subscribe(value => this.businessCustomerRequests = value);
  }

  ngOnInit(): void {
  }


  createBusinessCustomerRequest() {
    this.modalService.openBusinessCustomerRequestModal().afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.createBusinessCustomerRequest(value).subscribe(value1 => {
          this.businessCustomerRequests.push(value1);
          this.businessCustomerRequestList.updateTable();
        });
      }
    });
  }

  openDeleteBusinessCustomerRequestModal($event: number) {
    const context: ConfirmationModalContext = {
      headline: "Delete Business Customer Request",
      content: "Would you really like to delete this business customer request ?",
      severity: "warn"
    };
    this.modalService.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.deleteBusinessCustomerRequest($event).subscribe(value1 => {
          this.businessCustomerRequests = this.businessCustomerRequests.filter(value2 => value2.id !== $event);
          this.businessCustomerRequestList.updateTable();
        });
      }
    });
  }

  openEditBusinessCustomerRequestModal($event: BusinessCustomerRequestTO) {
    this.modalService.openBusinessCustomerRequestModal($event).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.updateBusinessCustomerRequest(value.id, value).subscribe(value1 => {
          const index = this.businessCustomerRequests.indexOf($event);
          this.businessCustomerRequests[index] = value1;
          this.businessCustomerRequestList.updateTable();
        });
      }
    });
  }

  openTransferModal($event: number) {
    const context: ConfirmationModalContext = {
      headline: "Transfer Business Customer Request",
      content: "Would you really like to transfer this business customer request ?",
      severity: "warn"
    };
    this.modalService.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.transferBusinessCustomerRequest($event).subscribe(value1 => {
          this.businessCustomerRequests = this.businessCustomerRequests.filter(value2 => value2.id !== $event);
          this.businessCustomerRequestList.updateTable();
        });
      }
    });
  }

  goToDetails($event: number) {
    this.router.navigateByUrl('volume-customers/requests/' + $event + '/details');
  }
}
