<form novalidate [formGroup]="formGroup">
  <div>
    <mat-form-field>
      <mat-label>Salutation*</mat-label>
      <mat-select formControlName="salutation" name="salutation">
        <mat-option *ngFor="let salutation of salutations" [value]="salutation.value">
          {{ salutation.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>Firstname*</mat-label>
      <input #firstName  matInput formControlName="firstName" name="firstName" type="text" maxlength="100"/>
      <mat-hint align="end">{{ firstName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Lastname*</mat-label>
      <input #lastName matInput formControlName="lastName" name="lastName" type="text" maxlength="100"/>
      <mat-hint align="end">{{ lastName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Info</mat-label>
      <input #info  matInput formControlName="info" name="info" maxlength="100"/>
      <mat-hint align="end">{{ info.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>Department</mat-label>
      <input #department  matInput formControlName="department" name="department" maxlength="100"/>
      <mat-hint align="end">{{ department.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input #email matInput formControlName="email" name="email" type="text" maxlength="100"/>
      <mat-hint align="end">{{ email.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>Phone</mat-label>
      <input #phone  matInput formControlName="phone" name="phone" type="text"/>
      <mat-hint align="end">{{ phone.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Mobile</mat-label>
      <input #mobile matInput formControlName="mobile" name="mobile" type="text" maxlength="100"/>
      <mat-hint align="end">{{ mobile.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
</form>
<div class="ti actions">
  <button mat-button (click)="onCancelClick.emit()">Cancel</button>
  <button [disabled]="formGroup.invalid" (click)="onSaveClick.emit(formGroup.value)" mat-flat-button color="accent">Save</button>
</div>
