import {Component, Inject, OnInit} from '@angular/core';
import {LogsService} from "../../../accounts/services/logs.service";
import {LogFilterTO} from "ti-frontend-shared";

@Component({
  selector: 'pa-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  constructor(private ls: LogsService, @Inject('ENV_NAME') private envName: string) { }

  ngOnInit(): void {
  }

  public get loader() {
    return (filter: LogFilterTO) => this.ls.loadAllLogs(filter);
  }
  public get source() {
    return this.envName === 'p' ? 'polly' : 'polly-' + this.envName;
  }
}
