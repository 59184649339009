import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PollyAdminGuard} from "../core/guards/polly-admin-guard.service";
import {PaymentServiceComponent} from "./components/payment-service/payment-service.component";
import {CreditCardsComponent} from "./components/credit-cards/credit-cards.component";
import {InvoicesComponent} from "./components/invoices/invoices.component";

const basePath: string = "payment/";
const routes: Routes = [
  {
    path: basePath + "payment-service",
    component: PaymentServiceComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "credit-cards",
    component: CreditCardsComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "invoices",
    component: InvoicesComponent,
    canActivate: [PollyAdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
