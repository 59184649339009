<div class="payments-container">
  <div class="header">
    <h1>Payments</h1>
    <div class="flex-spacer"></div>
    <div class="stats-overview" *ngIf="openPaymentInfo && !processing">
      <div class="stat single">
        <div class="value">
          <span> {{openPaymentInfo.openPaymentCount}}</span>
        </div>
        <div class="label">
          Open Payments
        </div>
      </div>
    </div>
    <div class="stats-overview" *ngIf="openPaymentInfo && !processing">
      <div class="stat single">
        <div class="value">
          <span> {{openPaymentInfo.sumNet | currency: 'EUR'}}</span>
        </div>
        <div class="label">
          Sum
        </div>
      </div>
    </div>
    <div *ngIf="!processing" (click)="processPayments()" class="create-payments-button">
      <button mat-fab matTooltip="Process all open payments"><mat-icon>euro_symbol</mat-icon></button>
    </div>
    <div *ngIf="!processing" (click)="createPayments()" class="create-payments-button">
      <button mat-fab matTooltip="Activate all scheduled subscriptions and create all open payments"><mat-icon>autorenew</mat-icon></button>
    </div>
  </div>
  <div *ngIf="!processing" class="detail-sub-section-container">
    <div class="detail-sub-section">
      <pa-details-sub-section [displayButton]="false" [headline]="'Open'" [isDataDefined]="openPayments" [noContentMessage]="'No open payments'">
      <pa-open-payments-list #openPaymentsList [openPayments]="openPayments" (onOpenPaymentClicked)="processSinglePayment($event)"></pa-open-payments-list>
      </pa-details-sub-section>
    </div>

    <div class="detail-sub-section">
      <pa-details-sub-section [displayButton]="false" [headline]="'Upcoming'" [isDataDefined]="upcomingPayments" [noContentMessage]="'No open payments'">
      <pa-upcoming-payments-list #upcomingPaymentsList [upcomingPayments]="upcomingPayments"></pa-upcoming-payments-list>
      </pa-details-sub-section>
    </div>

    <div class="detail-sub-section">
      <pa-details-sub-section [displayButton]="false" [headline]="'Due Volume Customers'" [isDataDefined]="volumeCustomers" [noContentMessage]="'No due volume customers'">
        <pa-due-volume-customers-list #dueVolumeCustomersList [dueVolumeCustomers]="volumeCustomers" (onVcClicked)="goToVcDetails($event)"></pa-due-volume-customers-list>
      </pa-details-sub-section>
    </div>
  </div>
  <div *ngIf="processing" class="processing-container">
    <div class="processing-text"><p>Processing all open payments...</p></div>
    <mat-progress-bar mode="determinate" [value]="processContainer.processedCount * 100 / openPaymentInfo.openPaymentCount"></mat-progress-bar>
    <div *ngIf="processingFinished" class="finish-stats">
      <div class="stats-overview">
        <div class="stat single">
          <div class="value">
            <span> {{processContainer.processedCount}}</span>
          </div>
          <div class="label">
            Processed Payments
          </div>
        </div>
      </div>
      <div class="stats-overview">
        <div class="stat single">
          <div class="value">
            <span> {{processContainer.processedAmount | currency: 'EUR'}}</span>
          </div>
          <div class="label">
            Processed Amount
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="processingFinished" class="finish-processing-button"><button mat-raised-button color="primary" (click)="finishProcessing()">Finish processing</button></div>
  </div>
</div>
