<div class="license-overview-container">
  <div class="header">
    <h1>Volume Customers</h1>
    <div class="flex-spacer"></div>
    <div (click)="addVolumeCustomer()" class="create-vc-button">
      <button mat-fab><mat-icon>add</mat-icon></button>
    </div>
  </div>
  <div *ngIf="volumeCustomers">
    <pa-volume-customer-list [volumeCustomers]="volumeCustomers" (onVolumeCustomerSelect)="goToDetails($event)"></pa-volume-customer-list>
  </div>
</div>
