import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'pa-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss']
})
export class SubNavigationComponent implements OnInit {

  @Input()
  public target: string;

  @Input()
  public routeAction: boolean = true;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  public navigate(): void {
    if (this.routeAction) {
      this.router.navigateByUrl(this.target);
    }
  }

}
