import {Observable} from "rxjs";
import {Inject, Injectable} from "@angular/core";
import {StompConfig, StompRService} from "@stomp/ng2-stompjs";
import {Message} from "@stomp/stompjs";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  constructor(
    @Inject('WS_SERVER_URL') private wsServerURL: string,
    private stomp: StompRService
  ) {
    this.initConnection();
  }

  private stompConfig: StompConfig = {
    url: 'ws://localhost:6030/treeit/vs/rest/v1/votingsocket',
    headers: {

    },
    heartbeat_in: 0, // Typical value 0 - disabled
    heartbeat_out: 20000, // Typical value 20000 - every 20 seconds
    reconnect_delay: 5000,
    debug: true
  };

  private initConnection(): void {
    if (!this.stomp.connected()) {
      const config = this.stompConfig;
      config.url = this.wsServerURL;
      this.stomp.config = config;
      this.stomp.initAndConnect();
    }
  }

  public getPaymentServiceSubscription(): Observable<Message> {
    console.log('State: ' + this.stomp.connected());
    return this.stomp.subscribe('/wspaymentbroker/payment');
  }

}
