<pa-sub-navigation target="/volume-customers/all">overview
</pa-sub-navigation>

<div *ngIf="volumeCustomer" class="volume-customer-detail-container">
  <div class="header">
    <h1>{{volumeCustomer.customerName}} ({{volumeCustomer.customerNumber}})</h1>
    <div class="flex-spacer"></div>
    <div>
      <button (click)="openEditVolumeCustomerModal()" mat-fab matTooltip="Edit volume customer">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div>
      <button (click)="openDeleteVolumeCustomerModal()" mat-fab color="warn"
              matTooltip="Delete volume customer">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="detail-sub-section-container">
    <div class="detail-sub-section">
      <pa-details-sub-section (onButtonClicked)="openSubscriptionInfoModal($event)"
                              [noContentMessage]="'No subscription infos added yet.'"
                              [isDataDefined]="volumeCustomer.pricePerLicence"
                              [headline]="'Subscription Infos'">
        <div class="volume-customer-detail">
          <div class="left">
            <div>Valid until:</div>
            <div>Package:</div>
            <div>Price per licence:</div>
            <div>Invoice intervall:</div>
          </div>
          <div class="right">
            <div>
              {{volumeCustomer.validUntil | date: 'dd. MMMM yyyy - HH:mm'}}
            </div>
            <div> {{volumeCustomer.subscriptionType}}</div>
            <div>
              {{volumeCustomer.pricePerLicence}}
            </div>
            <div> {{volumeCustomer.invoiceIntervalInMonth}} month</div>
          </div>
        </div>
      </pa-details-sub-section>
    </div>
    <div class="detail-sub-section">
      <pa-details-sub-section (onButtonClicked)="openBillingAddressModal($event)"
                              [noContentMessage]="'No billing address added yet.'"
                              [isDataDefined]="volumeCustomer.billingAddress"
                              [headline]="'Billing Address'">
        <pa-show-billing-address
          [billingAddress]="volumeCustomer.billingAddress"></pa-show-billing-address>
      </pa-details-sub-section>
    </div>
    <div class="detail-sub-section">
      <pa-details-sub-section (onButtonClicked)="openAddressModal($event)"
                              [noContentMessage]="'No address added yet.'"
                              [isDataDefined]="volumeCustomer.address" [headline]="'Address'">
        <pa-show-address [address]="volumeCustomer.address"></pa-show-address>
      </pa-details-sub-section>
    </div>
    <div class="detail-sub-section">
      <pa-details-sub-section (onButtonClicked)="openNotesModal($event)"
                              [noContentMessage]="'No notes added yet.'"
                              [isDataDefined]="volumeCustomer.notes" [headline]="'Notes'">
        <div class="show-notes-section">{{volumeCustomer.notes}}</div>
      </pa-details-sub-section>
    </div>

  </div>
  <div class="contacts-container">
    <pa-details-sub-section (onButtonClicked)="openContactModal($event)"
                            [icon]="'add'"
                            [noContentMessage]="'No contact person added yet.'"
                            [isDataDefined]="volumeCustomer.contactPeople"
                            [headline]="'Contact Person'">
      <pa-show-contact-person #showContacts
                              [contacts]="volumeCustomer.contactPeople"></pa-show-contact-person>
    </pa-details-sub-section>
  </div>
  <div class="manage-licences-container">
    <pa-licences-list [volumeCustomer]="volumeCustomer"></pa-licences-list>
  </div>
  <div class="manage-invoices-container">
    <pa-vc-invoices-list [volumeCustomer]="volumeCustomer"></pa-vc-invoices-list>
  </div>
</div>
