import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {BusinessCustomerRequestTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'pa-edit-create-business-customer-request-modal',
  templateUrl: './edit-create-business-customer-request-modal.component.html',
  styleUrls: ['./edit-create-business-customer-request-modal.component.scss']
})
export class EditCreateBusinessCustomerRequestModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public businessCustomerRequest: BusinessCustomerRequestTO;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCreateBusinessCustomerRequestModalComponent, BusinessCustomerRequestTO>,
    @Inject(MAT_DIALOG_DATA) public data: BusinessCustomerRequestTO
  ) {
    this.businessCustomerRequest = data;
    this.editMode = ApplicationHelper.isObjectDefined(data);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.patchValue(this.businessCustomerRequest);
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: BusinessCustomerRequestTO = this.formGroup.value;
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      companyName: this.fb.control(undefined, Validators.required),
      contactFirstName: this.fb.control(undefined),
      contactLastName: this.fb.control(undefined),
      contactMailAddress: this.fb.control(undefined, Validators.email),
      amountLicences: this.fb.control(undefined, Validators.required),
      adminMailAddress: this.fb.control(undefined),
      linkHash: this.fb.control(undefined),
      created: this.fb.control(undefined),
      expiring: this.fb.control(undefined),
      approved: this.fb.control(undefined),
      phoneNumber: this.fb.control(undefined),
      invoicingType: this.fb.control(undefined),
      invoiceMailAddress: this.fb.control(undefined, Validators.email),
      billingAddress: this.fb.control(undefined),
    });
  }

}
