import {Component, OnInit, ViewChild} from '@angular/core';
import {CreditCardInfoTO, ExpireNotificationTO} from "../../../shared/generated/transportObjects";
import {ManagementService} from "../../services/management.service";
import {ModalService} from "../../../core/services/modal.service";
import {SpinnerService} from "ti-frontend-shared";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {CreditCardsListComponent} from "../credit-cards-list/credit-cards-list.component";

@Component({
  selector: 'pa-credit-cards',
  templateUrl: './credit-cards.component.html',
  styleUrls: ['./credit-cards.component.scss']
})
export class CreditCardsComponent implements OnInit {

  @ViewChild('creditCardsListComponent')
  creditCardListComponent: CreditCardsListComponent;


  public cards: CreditCardInfoTO[];

  constructor(
    private paymentService: ManagementService,
    private modalService: ModalService,
    private loadingService: SpinnerService
  ) {
  }

  ngOnInit() {

  }

  public requestCreditCardsInformations(): void {
    this.loadingService.spin(true);
    this.paymentService.getExpiringCards().subscribe(res => {
      this.cards = res;
      this.loadingService.spin(false);
      this.creditCardListComponent.updateTable();
    }, () => this.loadingService.spin(false));
  }

  public notifyCardHolder(info: CreditCardInfoTO): void {
    const context: ConfirmationModalContext = {
      severity: "warn",
      headline: "Notify Card Holder",
      content: `Do you want to inform ${info.accountMail} about expiring credit card ?`
    };
    this.modalService.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.paymentService.notifyCardHoldingAccount(info).subscribe(
          () => {
            const note: ExpireNotificationTO = {
              notified: new Date().toISOString(), cardToken: info.token, accountOwner: ''
            };
            info.notifications.push(note);
          }
        );
      }
    });
  }
}
