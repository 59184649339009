<div *ngIf="info && info.app" class="metric-section">
  <h2>System Information</h2>
  <div class="content">
    <div class="info-headline">{{removeQuotes(info.app.name)}}</div>
    <div class="info-desc">{{removeQuotes(info.app.desc)}}</div>
  </div>
</div>

<div *ngIf="health" class="metric-section">
  <h2>System Health</h2>
  <div class="content">
    <h3 [ngClass]="statusClass(health.status)">Overall Status: {{health.status}}</h3>
    <div class="health">
      <div *ngIf="health.components.diskSpace" class="health-detail">
        <div class="detail-headline">Disk Space</div>
        <div class="detail-info">
          <div [ngClass]="statusClass(health.components.diskSpace.status)">Status: {{health.components.diskSpace.status}}
          </div>
          <div>Total: {{health.components.diskSpace.details.total | mb}}</div>
          <div>Free: {{health.components.diskSpace.details.free | mb}}</div>
          <div>Threshold: {{health.components.diskSpace.details.threshold | mb}}</div>
        </div>
      </div>
      <div *ngIf="health.components.db" class="health-detail">
        <div class="detail-headline">Database</div>
        <div class="detail-info">
          <div [ngClass]="statusClass(health.components.db.status)">Status: {{health.components.db.status}}</div>
          <div>Database: {{health.components.db.details.database}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="info && info.app" class="metric-section me">
  <h2>System Metrics</h2>
  <div class="content">
    <div *ngFor="let met of metrics" class="metric-entry">
      <div class="met-name">{{met.name}}</div>
      <div class="met-desc"><span *ngIf="met.description">{{met.description}}</span></div>
      <div *ngFor="let measure of met.measurements" class="metric-value">
        <div>{{getValueString(measure, met.baseUnit)}}</div>
      </div>
    </div>
  </div>
</div>
