import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BillingAddressTO} from "../../generated/transportObjects";
import {ApplicationHelper} from "../../util/application-helper";
import {MatSelectChange} from "@angular/material/select";
import {getNames} from 'country-list';


@Component({
  selector: 'pa-create-edit-billing-address',
  templateUrl: './create-edit-billing-address.component.html',
  styleUrls: ['./create-edit-billing-address.component.scss']
})
export class CreateEditBillingAddressComponent implements OnInit {
  public formGroup: FormGroup;
  @Input()
  billingAddress: BillingAddressTO;
  @Output()
  onCancelClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSaveClick: EventEmitter<BillingAddressTO> = new EventEmitter<BillingAddressTO>();

   readonly salutations;
   readonly customerTypes;
   readonly countries: string[];
   isBusinessCustomerType = true;


  constructor(private fb: FormBuilder) {
    this.salutations = ApplicationHelper.salutations;
    this.customerTypes = ApplicationHelper.customerTypes;
    this.countries = getNames();
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.billingAddress) {
      this.formGroup.patchValue(this.billingAddress);
    }
  }
  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      salutation: this.fb.control(undefined),
      degree: this.fb.control(undefined),
      addInfo: this.fb.control(undefined),
      city: this.fb.control(undefined, Validators.required),
      firstName: this.fb.control(undefined),
      lastName: this.fb.control(undefined),
      company: this.fb.control(undefined),
      street: this.fb.control(undefined, Validators.required),
      streetNumber: this.fb.control(undefined, Validators.required),
      zip: this.fb.control(undefined, Validators.required),
      country: this.fb.control(undefined, Validators.required),
      vatId: this.fb.control(undefined),
      type: this.fb.control('BUSINESS', Validators.required)
    });
  }

  customerTypeChange($event) {
    $event === 'BUSINESS' ? this.isBusinessCustomerType = true : this.isBusinessCustomerType = false;
    if (this.isBusinessCustomerType) {
      this.formGroup.controls.company.setValidators(Validators.required);
      this.formGroup.controls.salutation.clearValidators();
      this.formGroup.controls.firstName.clearValidators();
      this.formGroup.controls.lastName.clearValidators();
      this.formGroup.controls.salutation.reset();
      this.formGroup.controls.firstName.reset();
      this.formGroup.controls.lastName.reset();
      this.formGroup.controls.degree.reset();
    } else {
      this.formGroup.controls.lastName.setValidators(Validators.required);
      this.formGroup.controls.salutation.setValidators(Validators.required);
      this.formGroup.controls.firstName.setValidators(Validators.required);
      this.formGroup.controls.company.clearValidators();
      this.formGroup.controls.company.reset();
      this.formGroup.controls.vatId.reset();
    }
    this.formGroup.controls.company.updateValueAndValidity();
    this.formGroup.controls.salutation.updateValueAndValidity();
    this.formGroup.controls.firstName.updateValueAndValidity();
    this.formGroup.controls.lastName.updateValueAndValidity();
    this.formGroup.controls.salutation.updateValueAndValidity();
    this.formGroup.controls.firstName.updateValueAndValidity();
    this.formGroup.controls.lastName.updateValueAndValidity();
    this.formGroup.controls.degree.updateValueAndValidity();
  }

  public get businessCustomer(){ return this.isBusinessCustomerType;}

  checkSave() {
    this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});
    return this.formGroup.valid;
  }
}
