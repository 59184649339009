import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PollyAdminGuard} from "../core/guards/polly-admin-guard.service";
import {VolumeCustomerOverviewComponent} from "./components/volume-customer-overview/volume-customer-overview.component";
import {VolumeCustomerDetailsComponent} from "./components/volume-customer-details/volume-customer-details.component";
import {BusinessCustomerRequestOverviewComponent} from "./components/business-customer-request-overview/business-customer-request-overview.component";
import {BusinessCustomerRequestDetailsComponent} from "./components/business-customer-request-details/business-customer-request-details.component";

const basePath: string = "volume-customers/";
const routes: Routes = [
  {
    path: basePath + "all",
    component: VolumeCustomerOverviewComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + ":id/details",
    component: VolumeCustomerDetailsComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "requests",
    component: BusinessCustomerRequestOverviewComponent,
    canActivate: [PollyAdminGuard]
  },
  {
    path: basePath + "requests/:id/details",
    component: BusinessCustomerRequestDetailsComponent,
    canActivate: [PollyAdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VolumeCustomersRoutingModule { }
