import {Component, OnInit} from '@angular/core';
import {AccountsService} from "../../services/accounts.service";
import {FilterType} from "angular-google-charts";
import {AccountStatistics} from "../../../shared/generated/transportObjects";
import {SpinnerService} from "ti-frontend-shared";

@Component({
  selector: 'pa-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  public data: AccountStatistics;
  public controlFilterType;
  categoryData: any[] = [];
  pollsData: any[] = [];
  questionData: any[] = [];
  newAccountsChartData: any[] = [];
  categoryChartOptions: any;
  newAccountsChartOptions: any;
  pollsChartOptions: any;
  questionsChartOptions: any;

  constructor(
      private accountsService: AccountsService,
      private loading: SpinnerService
  ) {
    this.loading.spin(true);
    this.controlFilterType = FilterType.Category;
    this.accountsService.getAccountStatistics(12).subscribe(res => {
      this.data = res;
      this.categoryData.push(['Basic Monthly', this.data.basicMonth]);
      this.categoryData.push(['Basic Annual', this.data.basicYear]);
      this.categoryData.push(['Premium Monthly', this.data.premiumMonth]);
      this.categoryData.push(['Premium Annual', this.data.premiumYear]);
      this.categoryData.push(['Test Accounts', this.data.testAccounts]);
      this.categoryData.push(['VC Accounts', this.data.vc]);
      this.pollsData.push(['Live Votings', this.data.livePoll]);
      this.pollsData.push(['Duration Votings', this.data.durationPoll]);
      this.questionData.push(['Presenter Question', this.data.presenterQuestion]);
      this.questionData.push(['Feedback Question', this.data.feedbackQuestion]);
      this.questionData.push(['Multi-Select Question', this.data.multiSelectionQuestion]);
      this.questionData.push(['Single-Select Question', this.data.singleSelectionQuestion]);
      this.questionData.push(['Yes/No Question', this.data.yesNoQuestion]);
      this.questionData.push(['Keyword Question', this.data.wordCloudQuestion]);
      this.questionData.push(['Scala Question', this.data.scalaQuestion]);
      for (const tmp of this.data.newAccountsPerMonths) {
        this.newAccountsChartData.push([tmp.month, tmp.newAccounts]);
      }
    }, () => this.loading.spin(false), () => this.loading.spin(false));
    this.categoryChartOptions = {
      title: 'Account Types Polly.now',
      is3D: true,
      animation: {
        startup: true,
        easing: 'in',
        duration: 1000
      },
      backgroundColor: '#303030',
      legend: {
        textStyle: {color: 'white'}
      },
      titleTextStyle: {color: 'white'}
    };
    this.pollsChartOptions = {
      title: 'Voting Types Polly.now',
      is3D: true,
      animation: {
        startup: true,
        easing: 'in',
        duration: 1000
      },
      backgroundColor: '#303030',
      legend: {
        textStyle: {color: 'white'}
      },
      titleTextStyle: {color: 'white'}
    };
    this.questionsChartOptions = {
      title: 'Question Types Polly.now',
      is3D: true,
      animation: {
        startup: true,
        easing: 'in',
        duration: 1000
      },
      backgroundColor: '#303030',
      legend: {
        textStyle: {color: 'white'}
      },
      titleTextStyle: {color: 'white'}
    };
    this.newAccountsChartOptions = {
      title: 'New Polly Accounts per month',
      is3D: true,
      legend: {
        position: 'bottom',
        textStyle: {color: 'white'}
      },
      vAxis: {
        scaleType: 'linear',
        format: '#',
        textStyle: {color: 'white'}
      },
      hAxis: {
        textStyle: {color: 'white'}
      },
      animation: {
        startup: true,
        duration: 1000,
        easing: 'out'
      },
      backgroundColor: {
        stroke: 'white',
        fill: '#303030'
      },
      titleTextStyle: {color: 'white'}
    };
  }


  ngOnInit() {

  }

}
