<div *ngIf="volumeCustomers && volumeCustomers.length > 0; else novc">

  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="customerName" headerText="Customer-Name"></mat-text-column>
    <mat-text-column name="customerNumber" headerText="Customer-No"></mat-text-column>
    <mat-text-column name="validUntil" headerText="Valid until"></mat-text-column>
    <ng-container matColumnDef="numberOfLicenses">
      <th mat-header-cell *matHeaderCellDef>Lincenses</th>
      <td mat-cell *matCellDef="let vc">
        <span>{{vc.volumeClients ? vc.volumeClients.length : 0}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row class="selectable-row" (click)="onVolumeCustomerSelect.emit(vc.id)" *matRowDef="let vc; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #novc>
  <div class="no-volume-customers">
    <span class="material-icons">supervisor_account</span>
    <div>No volume customers currently</div>
  </div>
</ng-template>
