<h2>Create invoice</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div class="three fields">
      <mat-form-field>
        <mat-label>Amount of licences*</mat-label>
        <input matInput formControlName="amountLicences" name="amountLicences" type="number"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Price per licence (net)*</mat-label>
        <input matInput formControlName="pricePerLicence" name="pricePerLicence" type="number"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Tax*</mat-label>
        <input matInput formControlName="tax" name="tax" type="number"/>
      </mat-form-field>
    </div>
    <div class="one fields">
      <mat-form-field>
        <mat-label>Position descritpion*</mat-label>
        <textarea
          matInput
          #desc
          maxlength="255"
          formControlName="positionDescription"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
        <mat-hint align="end">{{ desc.value?.length || 0 }}/255</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

