<h2>{{editMode ? 'Edit' : 'Create'}} Volume Customer</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Customer Name*</mat-label>
        <input #cn matInput formControlName="customerName" name="customerName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ cn.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

