import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {InvoiceTO} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit, AfterViewInit {

  @Input()
  invoices: InvoiceTO[] = [];

  @Output()
  onBookClicked: EventEmitter<InvoiceTO> = new EventEmitter<InvoiceTO>();

  @Output()
  onBookAndNotifyClicked: EventEmitter<InvoiceTO> = new EventEmitter<InvoiceTO>();

  @Input()
  public visibleColumns: string[] = ["owner", "created", "type", "period", "amount", "actions"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<InvoiceTO>;

  constructor() {
  }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<InvoiceTO>(this.invoices);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<InvoiceTO>(this.invoices);
      this.tableDataSource.paginator = this.paginator;
    });
  }

}
