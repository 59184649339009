import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {EditCreateVolumeCustomerModalComponent} from '../../volume-customers/modals/edit-create-volume-customer-modal/edit-create-volume-customer-modal.component';
import {ConfirmationModalContext} from '../../shared/model/confirmation-modal-context';
import {ConfirmationModalComponent} from '../../shared/modal/confirmation-modal/confirmation-modal.component';
import {InformationModalContext} from '../../shared/model/information-modal-context';
import {InformationModalComponent} from '../../shared/modal/information-modal/information-modal.component';
import {EditCreateBusinessCustomerRequestModalComponent} from '../../volume-customers/modals/edit-create-business-customer-request-modal/edit-create-business-customer-request-modal.component';
import {
  BillingAddressTO,
  BusinessCustomerRequestTO, ContactPersonTO, VCAddressTO, VCInvoiceTO,
  VcSubscriptionInfoTO,
  VolumeCustomerTO, VolumeLicenceInvoiceTO
} from '../../shared/generated/transportObjects';
import {EditCreateSubscriptionInfoModalComponent} from '../../volume-customers/modals/edit-create-subscription-info-modal/edit-create-subscription-info-modal.component';
import {EditCreateAddressModalComponent} from "../../shared/modal/edit-create-address-modal/edit-create-address-modal.component";
import {EditCreateBillingAddressModalComponent} from "../../shared/modal/edit-create-billing-address-modal/edit-create-billing-address-modal.component";
import {EditCreateNotesModalComponent} from "../../volume-customers/modals/edit-create-notes-modal/edit-create-notes-modal.component";
import {AddLicenceModalComponent} from "../../volume-customers/modals/add-licence-modal/add-licence-modal.component";
import {AddMultipleLicencesModalComponent} from "../../volume-customers/modals/add-multiple-licences-modal/add-multiple-licences-modal.component";
import {
  CalculateUpgradePriceModalContext,
  CalculationResult,
  LicencesCalculationModalComponent
} from "../../volume-customers/modals/licences-calculation-modal/licences-calculation-modal.component";
import {EditCreateContactPersonModalComponent} from "../../shared/modal/edit-create-contact-person-modal/edit-create-contact-person-modal.component";
import {CreateVcInvoiceModalComponent} from "../../volume-customers/modals/create-vc-invoice-modal/create-vc-invoice-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog) {}

  private readonly small: string = '480px';
  private readonly medium: string = '680px';
  private readonly big: string = '980px';

  public openCreateEditVolumeCustomer(volumeCustomer?: VolumeCustomerTO): MatDialogRef<EditCreateVolumeCustomerModalComponent, VolumeCustomerTO> {
    return this.dialog.open(EditCreateVolumeCustomerModalComponent, {
      width: this.small,
      data: volumeCustomer
    });
  }

  public openConfirmationModal(context: ConfirmationModalContext): MatDialogRef<ConfirmationModalComponent, boolean> {
    return this.dialog.open(ConfirmationModalComponent, {
      width: this.small,
      data: context
    });
  }

  public openInformationModal(context: InformationModalContext): MatDialogRef<InformationModalComponent, void> {
    return this.dialog.open(InformationModalComponent, {
      width: this.small,
      data: context
    });
  }

  public openBusinessCustomerRequestModal(re?: BusinessCustomerRequestTO): MatDialogRef<EditCreateBusinessCustomerRequestModalComponent, BusinessCustomerRequestTO> {
    return this.dialog.open(EditCreateBusinessCustomerRequestModalComponent, {
      width: this.medium,
      data: re
    });
  }

  public opensubscriptionInfoModal( vc: VolumeCustomerTO, si?: VcSubscriptionInfoTO): MatDialogRef<EditCreateSubscriptionInfoModalComponent, VcSubscriptionInfoTO> {
    return this.dialog.open(EditCreateSubscriptionInfoModalComponent, {
      width: this.medium,
      data: {si,
      vc}
    });
  }

  public openAddressModal(address?: VCAddressTO): MatDialogRef<EditCreateAddressModalComponent, VCAddressTO> {
    return this.dialog.open(EditCreateAddressModalComponent, {
      width: this.medium,
      data: address
    });
  }

  public openBillingAddressModal(billingAddress?: BillingAddressTO): MatDialogRef<EditCreateBillingAddressModalComponent, BillingAddressTO> {
    return this.dialog.open(EditCreateBillingAddressModalComponent, {
      width: this.medium,
      data: billingAddress
    });
  }

  public openNotesModal(note?: string): MatDialogRef<EditCreateNotesModalComponent, string> {
    return this.dialog.open(EditCreateNotesModalComponent, {
      width: this.medium,
      data: note
    });
  }

  public openAddSingleLicenceModal(): MatDialogRef<AddLicenceModalComponent, string> {
    return this.dialog.open(AddLicenceModalComponent, {
      width: this.medium,
    });
  }

  public openAddMultipleLicenceModal(): MatDialogRef<AddMultipleLicencesModalComponent, string[]> {
    return this.dialog.open(AddMultipleLicencesModalComponent, {
      width: this.medium,
    });
  }

  public openLicencesCalculationModal(context: CalculateUpgradePriceModalContext): MatDialogRef<LicencesCalculationModalComponent, CalculationResult> {
    return this.dialog.open(LicencesCalculationModalComponent, {
      width: this.big,
      data: context
    });
  }

  public openContactPersonModal(contact?: ContactPersonTO): MatDialogRef<EditCreateContactPersonModalComponent, ContactPersonTO> {
    return this.dialog.open(EditCreateContactPersonModalComponent, {
      width: this.medium,
      data: contact
    });
  }

  public openCreateVCInvoiceModal(): MatDialogRef<CreateVcInvoiceModalComponent, VolumeLicenceInvoiceTO> {
    return this.dialog.open(CreateVcInvoiceModalComponent, {
      width: this.medium,
      data: undefined
    });
  }

}
