import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {ModalService} from './modal.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {InformationModalContext} from '../../shared/model/information-modal-context';

@Injectable({
  providedIn: 'root'
})
export class ErrorResponseInterceptorService implements HttpInterceptor {

  constructor(
    private ms: ModalService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(res => res,
        err => {
          if (err instanceof HttpErrorResponse && err.status === 418) {
            let context: InformationModalContext;
            context = {
              headline: err.error.header,
              content: err.error.message,
              severity: 'warn'
            };
            this.ms.openInformationModal(context);
          }
        }));
  }

}
