<div class="licences-container">
  <div class="header">
    <div>
    <h3>Manage licences {{licences && licences.length > 0 ? '(' + licences.length +' licences)' : ''}}</h3>
      <div *ngIf="volumeCustomer.recalled" class="account-canceled">Account canceled</div>
    </div>
    <div class="flex-spacer"></div>
    <div>
      <button mat-fab [matMenuTriggerFor]="menu">
        <mat-icon>miscellaneous_services</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="openLicenceCalculationModal()" mat-menu-item>New licences calculation</button>
        <mat-divider></mat-divider>
        <button (click)="openSetAllRunningModal()" mat-menu-item>Set all running</button>
        <button (click)="openRecallAllModal()" mat-menu-item>Recall all</button>
        <button (click)="openDeleteAllModal()" mat-menu-item>Delete all</button>
        <mat-divider></mat-divider>
        <button (click)="openAddLicenceModal()" mat-menu-item>Add licence</button>
        <button (click)="openAddLicencesAsCSVModal()" mat-menu-item>Add licences as CSV</button>
      </mat-menu>
    </div>
  </div>

<div *ngIf="licences && licences.length > 0; else noLicences">
  <table class="licences-table" mat-table [dataSource]="tableDataSource">
    <ng-container matColumnDef="clientId">
      <th mat-header-cell *matHeaderCellDef>User-Name</th>
      <td mat-cell *matCellDef="let licence">
        <div class="username-column">
        <mat-icon *ngIf="licence.vcAdmin">star</mat-icon>
        <span>
        {{licence.clientId}}
        </span>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="volumeClientStatus">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let licence">
        {{getClientStatusString(licence)}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="action-column" mat-cell *matCellDef="let licence">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="openDeleteModal(licence)" mat-menu-item>Delete</button>
          <button *ngIf="licence.volumeClientStatus === 'ACTIVATED' || licence.volumeClientStatus === 'NOREG'" (click)="openChangeAdminStatusModal(licence)" mat-menu-item>Change Admin State</button>
          <button *ngIf="licence.volumeClientStatus === 'DENIED'" (click)="openConvertModal(licence)" mat-menu-item>Convert</button>
          <button *ngIf="licence.volumeClientStatus === 'ORDERED'" (click)="openActivateModal(licence)" mat-menu-item>Activate</button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let bcr; columns: visibleColumns"></tr>
  </table>
</div>
<ng-template #noLicences>
  <div class="no-licences">
    <div class="material-icons">supervisor_account</div>
    <div>No licences currently</div>
  </div>
</ng-template>
</div>
