<div class="credit-cards-management-container">
  <div class="header">
    <h1>Credit Card Management</h1>
    <div class="flex-spacer"></div>
    <div (click)="requestCreditCardsInformations()" class="get-credit-cards-information">
      <button mat-fab matTooltip="Load Credit Card Information (advisedly!)"><mat-icon>cached</mat-icon></button>
    </div>
  </div>
  <div>
    <pa-credit-cards-list #creditCardsListComponent (onNotifyClicked)="notifyCardHolder($event)" [cards]="cards"></pa-credit-cards-list>
  </div>
</div>
