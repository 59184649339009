import { Injectable } from '@angular/core';
import {AuthenticationService} from "ti-frontend-shared";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.userToken) {
      request = request.clone({
        setHeaders: {
          "X-TI-Auth": 'Bearer ' + this.authenticationService.userToken,
        }
      });
      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
