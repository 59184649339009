import { Component, OnInit } from '@angular/core';
import {MetricsService} from "../../services/metrics.service";
import {MbPipe} from "ti-frontend-shared";

@Component({
  selector: 'pa-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {
  public health: any = undefined;
  public info: any = undefined;
  public metrics: any[] = new Array();

  private metricInformation: string[] = [
    "jvm.memory.max",
    "system.load.average.1m",
    "jvm.memory.used",
    "system.cpu.count",
    "http.server.requests",
    "jdbc.connections.active",
    "jdbc.connections.max",
    "jdbc.connections.min",
    "tomcat.threads.config.max",
    "jvm.memory.committed",
    "jvm.threads.daemon",
    "system.cpu.usage",
    "tomcat.threads.current",
    "jvm.threads.live",
    "jvm.threads.peak",
    "process.uptime",
    "tomcat.threads.busy",
    "process.cpu.usage",
    "jvm.classes.loaded",
    "jvm.classes.unloaded",
    "tomcat.global.request.max",
    "tomcat.global.sent",
    "tomcat.sessions.active.current",
    "tomcat.sessions.alive.max",
    "tomcat.sessions.expired",
    "process.files.open",
    "tomcat.global.request.max",
    "tomcat.global.request",
    "process.start.time",
  ];


  constructor(
    private metricsService: MetricsService,
    private mbp: MbPipe
  ) {
  }

  ngOnInit() {
    this.loadHealthInformation();
    this.loadSystemInfo();
    this.loadSystemMetrics();
  }

  private loadHealthInformation(): void {
    this.metricsService.getHealthInformation().subscribe(health => this.health = health);
  }

  private loadSystemInfo(): void {
    this.metricsService.getSystemInformation().subscribe(info => this.info = info);
  }

  private loadSystemMetrics(): void {
    let timeout = 500;
    for (let endpoint of this.metricInformation) {
      setTimeout(() => {
        this.metricsService.getSystemDetails(endpoint).subscribe(res => this.metrics.push(res));
      }, timeout);
      timeout = timeout + 150;
    }
  }

  public statusClass(status: string) {
    if (status === 'UP') {
      return 'success';
    } else {
      return 'error';
    }
  }

  public removeQuotes(input: string): string {
    return input.replace(new RegExp('\"', 'g'), '');
  }

  public getValueString(mesure: any, baseUnit: string) {
    if (mesure) {
      if (mesure.statistic === 'VALUE' && baseUnit === 'bytes') {
        return mesure.statistic + ' ' + this.mbp.transform(mesure.value);
      } else {
        return mesure.statistic + ' ' + mesure.value;
      }
    }
  }
}
