import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {Observable} from "rxjs";
import {
  AccountStatistics,
  AccountOverviewTO,
  SearchTO,
  AccountAdminInfoTO
} from "../../shared/generated/transportObjects";
import {ApplicationHelper, Page} from "ti-frontend-shared";

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    @Inject('VOTING_SERVER_URL') private serverUrl: string,
    private http: HttpClient
  ) {
  }

  public getAccountStatistics(months: number): Observable<AccountStatistics> {
    const url = this.serverUrl + ApiEndpoints.accountStatistics + months.toString();
    return this.http.get<AccountStatistics>(url);
  }

  public searchAccount(search: SearchTO): Observable<Page<AccountOverviewTO>> {
    const url = this.serverUrl + ApiEndpoints.searchAccount;
    const params = this.createSearchToHttpParams(search);
    return this.http.get<Page<AccountOverviewTO>>(url, {params});
  }

  public getAccountInfo(accountId: number): Observable<AccountAdminInfoTO> {
    const url = this.serverUrl + ApiEndpoints.accountInfo(accountId);
    return this.http.get<AccountAdminInfoTO>(url);
  }

  public deleteAccount(accountId: number): Observable<any> {
    const url = this.serverUrl + ApiEndpoints.deleteAccount(accountId);
    return this.http.delete<any>(url);
  }

  private createSearchToHttpParams(searchTO: SearchTO): HttpParams {
    let params = new HttpParams();
    if (searchTO.name) {
      params = params.append("name", searchTO.name);
    }
    if (ApplicationHelper.isObjectDefined(searchTO.size)) {
      params = params.append("size", searchTO.size.toString());
    }

    if (ApplicationHelper.isObjectDefined(searchTO.page)) {
      params = params.append("page", searchTO.page.toString());
    }
    return params;
  }
}
