import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {VolumeCustomerTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'pa-edit-create-volume-customer-modal',
  templateUrl: './edit-create-volume-customer-modal.component.html',
  styleUrls: ['./edit-create-volume-customer-modal.component.scss']
})
export class EditCreateVolumeCustomerModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public volumeCustomer: VolumeCustomerTO;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCreateVolumeCustomerModalComponent, VolumeCustomerTO>,
    @Inject(MAT_DIALOG_DATA) public data: VolumeCustomerTO
  ) {
    this.volumeCustomer = data;
    this.editMode = ApplicationHelper.isObjectDefined(data);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.patchValue(this.volumeCustomer);
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
        const result: VolumeCustomerTO = this.formGroup.value;
        this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      customerName: this.fb.control(undefined, Validators.required),
      address: this.fb.control(undefined),
      billingAddress: this.fb.control(undefined),
      contactPeople: this.fb.control(undefined),
      customerNumber: this.fb.control(undefined),
      invoiceIntervalInMonth: this.fb.control(undefined),
      invoicingMailAddress: this.fb.control(undefined),
      invoicingType: this.fb.control(undefined),
      notes: this.fb.control(undefined),
      pricePerLicence: this.fb.control(undefined),
      recalled: this.fb.control(undefined),
      subscriptionType: this.fb.control(undefined),
      validUntil: this.fb.control(undefined),
      volumeClients: this.fb.control(undefined)
    });
  }

}
