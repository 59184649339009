import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import { VolumeCustomersRoutingModule } from './volume-customers-routing.module';
import {SharedModule} from "../shared/shared.module";
import { VolumeCustomerListComponent } from './components/volume-customer-list/volume-customer-list.component';
import { EditCreateVolumeCustomerModalComponent } from './modals/edit-create-volume-customer-modal/edit-create-volume-customer-modal.component';
import { VolumeCustomerOverviewComponent } from './components/volume-customer-overview/volume-customer-overview.component';
import { BusinessCustomerRequestOverviewComponent } from './components/business-customer-request-overview/business-customer-request-overview.component';
import { VolumeCustomerDetailsComponent } from './components/volume-customer-details/volume-customer-details.component';
import { BusinessCustomerRequestListComponent } from './components/business-customer-request-list/business-customer-request-list.component';
import { EditCreateBusinessCustomerRequestModalComponent } from './modals/edit-create-business-customer-request-modal/edit-create-business-customer-request-modal.component';
import { BusinessCustomerRequestDetailsComponent } from './components/business-customer-request-details/business-customer-request-details.component';
import { EditCreateSubscriptionInfoModalComponent } from './modals/edit-create-subscription-info-modal/edit-create-subscription-info-modal.component';
import { EditCreateNotesModalComponent } from './modals/edit-create-notes-modal/edit-create-notes-modal.component';
import { LicencesListComponent } from './components/licences-list/licences-list.component';
import { LicencesCalculationModalComponent } from './modals/licences-calculation-modal/licences-calculation-modal.component';
import { AddLicenceModalComponent } from './modals/add-licence-modal/add-licence-modal.component';
import { AddMultipleLicencesModalComponent } from './modals/add-multiple-licences-modal/add-multiple-licences-modal.component';
import { CreateVcInvoiceModalComponent } from './modals/create-vc-invoice-modal/create-vc-invoice-modal.component';
import { VcInvoicesListComponent } from './components/vc-invoices-list/vc-invoices-list.component';


@NgModule({
  declarations: [VolumeCustomerListComponent, EditCreateVolumeCustomerModalComponent, VolumeCustomerOverviewComponent, BusinessCustomerRequestOverviewComponent, VolumeCustomerDetailsComponent, BusinessCustomerRequestListComponent, EditCreateBusinessCustomerRequestModalComponent, BusinessCustomerRequestDetailsComponent, EditCreateSubscriptionInfoModalComponent, EditCreateNotesModalComponent, LicencesListComponent, LicencesCalculationModalComponent, AddLicenceModalComponent, AddMultipleLicencesModalComponent, CreateVcInvoiceModalComponent, VcInvoicesListComponent],
  imports: [
    CommonModule,
    SharedModule,
    VolumeCustomersRoutingModule
  ],
  providers: [DatePipe]
})
export class VolumeCustomersModule { }
