import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import * as moment from "moment";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {
  BillingAddressTO,
  BusinessCustomerRequestTO, ContactPersonTO, VCAddressTO, VCInvoiceTO,
  VcSubscriptionInfoTO, VolumeClientTO,
  VolumeCustomerTO, VolumeLicenceInvoiceTO
} from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: 'root'
})
export class VolumeCustomerService {

  constructor(
    @Inject('VOTING_SERVER_URL') private serverUrl: string,
    private http: HttpClient
  ) {
  }

  public createVC(customerName: string): Observable<VolumeCustomerTO> {
    const url = this.serverUrl + ApiEndpoints.createVC;
    return this.http.post<VolumeCustomerTO>(url, {}, {params: {'customerName': customerName}});
  }

  public deleteVC(id: number) {
    const url = this.serverUrl + ApiEndpoints.deleteVC + id;
    return this.http.delete(url, {});
  }

  public getAllVC(): Observable<VolumeCustomerTO[]> {
    const url = this.serverUrl + ApiEndpoints.getAllVC;
    return this.http.get<VolumeCustomerTO[]>(url);
  }

  public renameVC(customerId: number, name: string): Observable<string> {
    const url = this.serverUrl + ApiEndpoints.renameVC + customerId.toString();
    return this.http.post(url, name, {responseType: 'text'});
  }

  public findVC(customerId: number): Observable<VolumeCustomerTO> {
    const url = this.serverUrl + ApiEndpoints.findVC + customerId.toString();
    return this.http.get<VolumeCustomerTO>(url);
  }

  public createBusinessCustomerRequest(request: BusinessCustomerRequestTO): Observable<BusinessCustomerRequestTO> {
    const url = this.serverUrl + ApiEndpoints.createBusinessCustomerRequest;
    return this.http.post<BusinessCustomerRequestTO>(url, request);
  }

  public findAllBusinessCustomerRequests(): Observable<BusinessCustomerRequestTO[]> {
    const url = this.serverUrl + ApiEndpoints.findAllBusinessCustomerRequests;
    return this.http.get<BusinessCustomerRequestTO[]>(url);
  }

  public findBusinessCustomerRequest(requestId: number): Observable<BusinessCustomerRequestTO> {
    const url = this.serverUrl + ApiEndpoints.findBusinessCustomerRequest + requestId.toString();
    return this.http.get<BusinessCustomerRequestTO>(url);
  }

  public deleteBusinessCustomerRequest(requestId: number) {
    const url = this.serverUrl + ApiEndpoints.deleteBusinessCustomerRequest + requestId.toString();
    return this.http.delete(url);
  }

  public transferBusinessCustomerRequest(requestId: number) {
    const url = this.serverUrl + ApiEndpoints.transferBusinessCustomerRequest + requestId.toString();
    return this.http.put(url, null);
  }

  public updateBusinessCustomerRequest(requestId: number, request: BusinessCustomerRequestTO): Observable<BusinessCustomerRequestTO> {
    const url = this.serverUrl + ApiEndpoints.updateBusinessCustomerRequest + requestId.toString();
    return this.http.put<BusinessCustomerRequestTO>(url, request);
  }

  public saveSubscriptionInfo(customerId: number, vcSubInfo: VcSubscriptionInfoTO): Observable<VolumeCustomerTO> {
    const url = this.serverUrl + ApiEndpoints.saveSubscriptionInfo + customerId;
    vcSubInfo.validUntil = new Date(moment(vcSubInfo.validUntil).format('YYYY-MM-DD'));
    return this.http.post<VolumeCustomerTO>(url, vcSubInfo);
  }

  public changeCustomerNote(customerId: number, note: string): Observable<VolumeCustomerTO> {
    const url = this.serverUrl + ApiEndpoints.changeCustomerNote + customerId.toString();
    return this.http.post<VolumeCustomerTO>(url, note);
  }

  public editAddress(customerId: number, address: VCAddressTO): Observable<VCAddressTO> {
    const url = this.serverUrl + ApiEndpoints.editVCAddress;
    return this.http.post<VCAddressTO>(url, address, {params: {'customerId': customerId.toString()}});
  }

  public editBillingAddress(customerId: number, address: BillingAddressTO): Observable<BillingAddressTO> {
    const url = this.serverUrl + ApiEndpoints.editVCBillingAddress;
    return this.http.post<BillingAddressTO>(url, address, {params: {'customerId': customerId.toString()}});
  }

  public deleteAllClients(customerId: number): Observable<VolumeCustomerTO> {
    const url = this.serverUrl + ApiEndpoints.deleteAllClients + customerId.toString();
    return this.http.post<VolumeCustomerTO>(url, null);
  }

  public activateOrderedClient(vcId: number): Observable<VolumeClientTO> {
    const url = this.serverUrl + ApiEndpoints.activateOrderedClient + vcId.toString();
    return this.http.post<VolumeClientTO>(url, null);
  }

  public recallAllClientAccounts(customerId: number): Observable<string[]> {
    const url = this.serverUrl + ApiEndpoints.recallAllClients + customerId.toString();
    return this.http.post<string[]>(url, null);
  }

  public setAllClientsRunning(customerId: number): Observable<string[]> {
    const url = this.serverUrl + ApiEndpoints.setAllClientsRunning + customerId.toString();
    return this.http.post<string[]>(url, null);
  }

  public addLicences(customerId: number, licences: string[]): Observable<VolumeClientTO[]> {
    const url = this.serverUrl + ApiEndpoints.addVolumeCustomerClients;
    return this.http.post<VolumeClientTO[]>(url, licences, {params: {'customerId': customerId.toString()} });
  }

  public deleteVolumeClient(clientId: number): Observable<VolumeClientTO[]> {
    const url = this.serverUrl + ApiEndpoints.deleteVolumeClient + clientId.toString();
    return this.http.delete<VolumeClientTO[]>(url);
  }

  public convertNonTestUser(clientId: number): Observable<VolumeClientTO> {
    const url = this.serverUrl + ApiEndpoints.convertNonTestUser + clientId.toString();
    return this.http.post<VolumeClientTO>(url, null);
  }

  public switchAdminState(clientId: number): Observable<VolumeClientTO[]> {
    const url = this.serverUrl + ApiEndpoints.switchAdminState + clientId.toString();
    return this.http.post<VolumeClientTO[]>(url, null);
  }

  public saveContactPerson(customerId: number, contactPerson: ContactPersonTO): Observable<ContactPersonTO[]> {
    const url = this.serverUrl + ApiEndpoints.saveContactPerson;
    return this.http.post<ContactPersonTO[]>(url, contactPerson, {params: {'customerId': customerId.toString()}});
  }

  public deleteContactPerson(contactPersonId: number, vcId: number): Observable<ContactPersonTO[]> {
    const url = this.serverUrl + ApiEndpoints.deleteContactPerson;
    return this.http.delete<ContactPersonTO[]>(url, {params: {'vcId': vcId.toString(), 'contactPersonId': contactPersonId.toString()}});
  }

  public getInvoicesForVC(customerId: number): Observable<VCInvoiceTO[]> {
    const url = this.serverUrl + ApiEndpoints.getInvoicesForCustomer + customerId.toString();
    return this.http.get<VCInvoiceTO[]>(url);
  }

  public createInvoiceForVC(customerId: number, invoiceInfo: VolumeLicenceInvoiceTO) {
    const url = this.serverUrl + ApiEndpoints.createVCInvoice + customerId.toString();
    return this.http.post(url, invoiceInfo);
  }

  public printInvoice(invoiceId: number): Observable<Blob> {
    const url = this.serverUrl + ApiEndpoints.printInvoice + invoiceId.toString();
    return this.http.get(url, {responseType: 'blob'});
  }

}
