<form novalidate [formGroup]="formGroup">
  <div class="choose-type">
    <mat-radio-group (ngModelChange)="customerTypeChange($event)" formControlName="type"
                     name="type">
      <mat-radio-button *ngFor="let type of customerTypes" [value]="type.value">
        {{ type.label }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div *ngIf="businessCustomer" class="divider-section">
    <h3>Company information</h3>
  </div>
  <div *ngIf="businessCustomer">
    <mat-form-field>
      <mat-label>Company*</mat-label>
      <input #company matInput formControlName="company" name="company" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ company.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields" *ngIf="businessCustomer">
    <mat-form-field>
      <mat-label>Firstname</mat-label>
      <input #firstName matInput formControlName="firstName" name="firstName" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ firstName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Lastname</mat-label>
      <input #lastName matInput formControlName="lastName" name="lastName" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ lastName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="businessCustomer">
    <mat-form-field>
      <mat-label>Vat Id</mat-label>
      <input #vatId matInput formControlName="vatId" name="vatId" type="text" maxlength="100"/>
      <mat-hint align="end">{{ vatId.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="!businessCustomer" class="divider-section">
    <h3>Person information</h3>
  </div>
  <div class="two fields" *ngIf="!businessCustomer">
    <mat-form-field>
      <mat-label>Salutation*</mat-label>
      <mat-select formControlName="salutation" name="salutation">
        <mat-option *ngFor="let salutation of salutations" [value]="salutation.value">
          {{ salutation.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Degree</mat-label>
      <input #degree matInput formControlName="degree" name="degree" type="text" maxlength="100"/>
      <mat-hint align="end">{{ degree.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields" *ngIf="!businessCustomer">
    <mat-form-field>
      <mat-label>Firstname*</mat-label>
      <input #firstName matInput formControlName="firstName" name="firstName" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ firstName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Lastname*</mat-label>
      <input #lastName matInput formControlName="lastName" name="lastName" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ lastName.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="divider-section">
    <h3>Address information</h3>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>Street*</mat-label>
      <input #street matInput formControlName="street" name="street" type="text" maxlength="100"/>
      <mat-hint align="end">{{ street.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Street No.*</mat-label>
      <input #streetNumber matInput formControlName="streetNumber" name="streetNumber" type="text"
             maxlength="100"/>
      <mat-hint align="end">{{ streetNumber.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Ad Info</mat-label>
      <input #ad matInput formControlName="addInfo" name="addInfo" type="text" maxlength="100"/>
      <mat-hint align="end">{{ ad.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div class="two fields">
    <mat-form-field>
      <mat-label>ZIP*</mat-label>
      <input #zip matInput formControlName="zip" name="zip" type="text" maxlength="5"
             minlength="5"/>
      <mat-hint align="end">{{ zip.value?.length || 0 }}/5</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>City*</mat-label>
      <input #city matInput formControlName="city" name="city" type="text" maxlength="100"/>
      <mat-hint align="end">{{ city.value?.length || 0 }}/100</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Country*</mat-label>
      <mat-select formControlName="country" name="country">
        <mat-option *ngFor="let country of countries" [value]="country">
          {{ country }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
<div class="ti actions">
  <button mat-button (click)="onCancelClick.emit()">Cancel</button>
  <button [disabled]="!checkSave()" (click)="onSaveClick.emit(formGroup.value)" mat-flat-button
          color="accent">Save
  </button>
</div>
