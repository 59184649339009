import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'pa-licences-calculation-modal',
  templateUrl: './licences-calculation-modal.component.html',
  styleUrls: ['./licences-calculation-modal.component.scss']
})
export class LicencesCalculationModalComponent implements OnInit {

  public formGroup: FormGroup;
  public result: CalculationResult;
  public context: CalculateUpgradePriceModalContext;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LicencesCalculationModalComponent, CalculationResult>,
    @Inject(MAT_DIALOG_DATA) public data: CalculateUpgradePriceModalContext
  ) {
    this.context = data;
  }

  ngOnInit() {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      intervalInMonths: this.fb.control(this.context.intervalInMonths, Validators.required),
      validUntil: this.fb.control(this.context.validUntil, Validators.required),
      pricePerLicence: this.fb.control(this.context.pricePerLicence, Validators.required),
      amountLicences: this.fb.control(this.context.amountLicences, Validators.required)
    });
  }

  public calculate(): void {
    const intervalInMonth: number = this.formGroup.get('intervalInMonths').value;
    const validUntil: Moment = moment(this.formGroup.get('validUntil').value);
    const pricePerLicence: number = this.formGroup.get('pricePerLicence').value;
    const amountLicences: number = this.formGroup.get('amountLicences').value;
    const today: Moment = moment(new Date());
    const intervalStart: Moment = moment(this.formGroup.get('validUntil').value);
    intervalStart.subtract(intervalInMonth, 'months').toDate();
    const allIntervalDays: number = validUntil.diff(intervalStart, 'days');
    const daysLeft: number = validUntil.diff(today, 'days');
    const upgradePricePerLicence: number = Math.round((pricePerLicence * daysLeft / allIntervalDays) * 100) / 100;
    const upgradeIntervalString: string = 'Neubuchung Lizenzen für Restlaufzeit ' + today.format('DD.MM.YYYY') + ' - ' + validUntil.format('DD.MM.YYYY');
    const result: CalculationResult = {
      pricePerLicence: upgradePricePerLicence,
      amountLicences,
      totalPrice: upgradePricePerLicence * amountLicences,
      upgradeIntervalString
    };
    this.result = result;
  }

  public createInvoice(): void {
    this.dialogRef.close(this.result);
  }

}

export interface CalculateUpgradePriceModalContext {
  intervalInMonths: number;
  validUntil: Date;
  pricePerLicence: number;
  amountLicences: number;
}

export interface CalculationResult {
  pricePerLicence: number;
  totalPrice: number;
  amountLicences: number;
  upgradeIntervalString: string;
}


