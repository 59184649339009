import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MbPipe, TiFrontendSharedModule} from 'ti-frontend-shared';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {ConfirmationModalComponent} from './modal/confirmation-modal/confirmation-modal.component';
import {InformationModalComponent} from './modal/information-modal/information-modal.component';
import {SubNavigationComponent} from './components/sub-navigation/sub-navigation.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatMomentDateModule, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatTooltipModule} from "@angular/material/tooltip";
import { CreateEditAddressComponent } from './components/create-edit-address/create-edit-address.component';
import { DetailsSubSectionComponent } from './components/details-sub-section/details-sub-section.component';
import { ShowAddressComponent } from './components/show-address/show-address.component';
import { ShowBillingAddressComponent } from './components/show-billing-address/show-billing-address.component';
import { CreateEditBillingAddressComponent } from './components/create-edit-billing-address/create-edit-billing-address.component';
import { EditCreateAddressModalComponent } from './modal/edit-create-address-modal/edit-create-address-modal.component';
import { EditCreateBillingAddressModalComponent } from './modal/edit-create-billing-address-modal/edit-create-billing-address-modal.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatDividerModule} from "@angular/material/divider";
import {MatCardModule} from "@angular/material/card";
import { EditCreateContactPersonModalComponent } from './modal/edit-create-contact-person-modal/edit-create-contact-person-modal.component';
import { EditCreateContactPersonComponent } from './components/edit-create-contact-person/edit-create-contact-person.component';
import { ShowContactPersonComponent } from './components/show-contact-person/show-contact-person.component';
import {GoogleChartsModule} from "angular-google-charts";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatPaginatorModule} from "@angular/material/paginator";

export const DateFormats = {
  parse: {
    dateInput: ["YYYY-MM-DD", "DD.MM.YYYY"]
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "YYYY MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM"
  }
};


@NgModule({
  declarations: [ConfirmationModalComponent, InformationModalComponent, SubNavigationComponent, CreateEditAddressComponent, DetailsSubSectionComponent, ShowAddressComponent, ShowBillingAddressComponent, CreateEditBillingAddressComponent, EditCreateAddressModalComponent, EditCreateBillingAddressModalComponent, EditCreateContactPersonModalComponent, EditCreateContactPersonComponent, ShowContactPersonComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TiFrontendSharedModule,
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatRadioModule,
    MatDividerModule,
    MatCardModule,
    GoogleChartsModule,
    MatPaginatorModule,
    MatProgressBarModule
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DateFormats},
    MbPipe,
  ],
  exports: [
    SubNavigationComponent,
    ConfirmationModalComponent,
    InformationModalComponent,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TiFrontendSharedModule,
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    DetailsSubSectionComponent,
    ShowBillingAddressComponent,
    ShowAddressComponent,
    MatRadioModule,
    MatDividerModule,
    MatCardModule,
    ShowContactPersonComponent,
    GoogleChartsModule,
    MatProgressBarModule,
    MatPaginatorModule,
  ]
})
export class SharedModule { }
