import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import {SharedModule} from "../shared/shared.module";
import { LogsComponent } from './components/logs/logs.component';
import { MetricsComponent } from './components/metrics/metrics.component';


@NgModule({
  declarations: [LogsComponent, MetricsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReportsRoutingModule
  ],
})
export class ReportsModule { }
