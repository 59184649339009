import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper} from "../../../shared/util/application-helper";

@Component({
  selector: 'pa-edit-create-notes-modal',
  templateUrl: './edit-create-notes-modal.component.html',
  styleUrls: ['./edit-create-notes-modal.component.scss']
})
export class EditCreateNotesModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public note: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCreateNotesModalComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.note = data;
    this.editMode = ApplicationHelper.isObjectDefined(data);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.controls['note'].setValue(this.note);
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
      const result: string = this.formGroup.controls['note'].value;
      this.dialogRef.close(result);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      note: this.fb.control(undefined),
    });
  }

}
