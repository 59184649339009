import {Component, Inject, OnInit} from '@angular/core';
import {
  BusinessCustomerRequestTO,
  VolumeCustomerTO
} from "../../../shared/generated/transportObjects";
import {ActivatedRoute, Router} from "@angular/router";
import {VolumeCustomerService} from "../../services/volume-customers.service";
import {SpinnerService} from "ti-frontend-shared";
import {ModalService} from "../../../core/services/modal.service";
import * as moment from "moment";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
@Component({
  selector: 'pa-business-customer-request-details',
  templateUrl: './business-customer-request-details.component.html',
  styleUrls: ['./business-customer-request-details.component.scss']
})
export class BusinessCustomerRequestDetailsComponent implements OnInit {
  public businessCustomerRequestTO: BusinessCustomerRequestTO;
  public copied: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private volumeCustomerservice: VolumeCustomerService,
    @Inject('POLLY_CLIENT_URL') private pollyClientURL: string,
    private spinner: SpinnerService,
    private ms: ModalService,
    private businessCustomerService: VolumeCustomerService,
    private router: Router,
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    const id: number = this.activatedRoute.snapshot.params["id"];
    this.volumeCustomerservice.findBusinessCustomerRequest(id).subscribe(value => {
      this.businessCustomerRequestTO = value;
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

  public isExpired(dateString: string) {
    return moment(dateString).isBefore(moment());
  }

  public copyAccessLink(): void {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 1500);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.pollyClientURL + this.businessCustomerRequestTO.linkHash;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  deleteBusinessCustomerRequest() {
    const context: ConfirmationModalContext = {
      headline: "Delete Business Customer Request",
      content: "Would you really like to delete this business customer request ?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.deleteBusinessCustomerRequest(this.businessCustomerRequestTO.id).subscribe(value1 => {
          this.router.navigateByUrl("/volume-customers/requests");
        });
      }
    });
  }

  public get  transferable(): boolean {
    return this.businessCustomerRequestTO.approved != null && this.businessCustomerRequestTO.approved !== undefined;
  }

  transferBusinessCustomerRequest() {
    const context: ConfirmationModalContext = {
      headline: "Transfer Business Customer Request",
      content: "Would you really like to transfer this business customer request ?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.transferBusinessCustomerRequest(this.businessCustomerRequestTO.id).subscribe(value1 => {
          this.router.navigateByUrl("/volume-customers/all");
        });
      }
    });
  }

  editBusinessCustomerRequest() {
    this.ms.openBusinessCustomerRequestModal(this.businessCustomerRequestTO).afterClosed().subscribe(value => {
      if (value) {
        this.businessCustomerService.updateBusinessCustomerRequest(value.id, value).subscribe(value1 => {
          this.businessCustomerRequestTO = value1;
        });
      }
    });
  }
}
