import {InjectionToken} from "@angular/core";

export class AppConfig {
  production: boolean;
  adminServerUrl: string;
  serverUrl: string;
  name: string;
  votingServerUrl: string;
  votingClientUrl: string;
  wsConnectionURL: string;
}

export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
