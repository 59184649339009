<h2>{{editMode ? 'Edit' : 'Create'}} Subscription Info</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div class="two fields">
      <mat-form-field>
        <mat-label>Price per Licence*</mat-label>
        <input matInput formControlName="pricePerLicence" name="pricePerLicence" type="number" placeholder="24.99"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice intervall in month*</mat-label>
        <input matInput formControlName="invoiceIntervalInMonth" name="invoiceIntervalInMonth" type="number" placeholder="3"/>
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>Package*</mat-label>
        <mat-select formControlName="subscriptionType" name="subscriptionType">
          <mat-option *ngFor="let type of subscriptionTypes" [value]="type.value">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
      <mat-label>Valid until*</mat-label>
      <input matInput formControlName="validUntil" [matDatepicker]="datePicker" />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

