import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  VolumeClientTO, VolumeCustomerTO
} from "../../../shared/generated/transportObjects";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ModalService} from "../../../core/services/modal.service";
import {DatePipe} from "@angular/common";
import {VolumeCustomerService} from "../../services/volume-customers.service";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {CalculateUpgradePriceModalContext} from "../../modals/licences-calculation-modal/licences-calculation-modal.component";

@Component({
  selector: 'pa-licences-list',
  templateUrl: './licences-list.component.html',
  styleUrls: ['./licences-list.component.scss']
})
export class LicencesListComponent implements OnInit, AfterViewInit {

  licences: VolumeClientTO[] = [];
  @Input()
  volumeCustomer: VolumeCustomerTO;

  @Output()
  onRequestSelect: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public visibleColumns: string[] = ["clientId", "volumeClientStatus", "actions"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;
  public tableDataSource: MatTableDataSource<VolumeClientTO>;

  constructor(private ms: ModalService, private licenceService: VolumeCustomerService, private datePipe: DatePipe, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.licences = this.volumeCustomer.volumeClients;
    this.tableDataSource = new MatTableDataSource<VolumeClientTO>(this.licences);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<VolumeClientTO>(this.licences);
      this.tableDataSource.paginator = this.paginator;
    });
  }

  public getClientStatusString(client: VolumeClientTO): string {
    let result: string;
    switch (client.volumeClientStatus) {
      case "RECALLED": {
        result = 'Recalled (' + this.datePipe.transform(client.recalled) + ')';
        break;
      }
      case "ORDERED": {
        result = 'Ordered (' + this.datePipe.transform(client.ordered) + ')';
        break;
      }
      case "NOREG": {
        result = 'Not registered';
        break;
      }
      case "DENIED": {
        result = 'Denied';
        break;
      }
      case "ACTIVATED": {
        result = 'Activated (' + this.datePipe.transform(client.activated) + ')';
        break;
      }
      default: {
        result = 'Error';
      }
    }
    return result;
  }

  openDeleteModal(licence: VolumeClientTO) {
    const context: ConfirmationModalContext = {
      headline: "Delete volume client licence",
      content: "Would you really like to delete the volume client licence for " + licence.clientId + "?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.deleteVolumeClient(licence.id).subscribe(value1 => {
          this.licences = value1;
          this.updateTable();
        });
      }
    });
  }

  openChangeAdminStatusModal(licence: VolumeClientTO) {
    const context: ConfirmationModalContext = {
      headline: "Change admin state",
      content: "Would you really like to to change the admin state for " + licence.clientId + "?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.switchAdminState(licence.id).subscribe(value1 => {
          this.licences = value1;
          this.updateTable();
        });
      }
    });
  }

  openLicenceCalculationModal() {
    const amount = this.licences.filter(vc => 'ORDERED' === vc.volumeClientStatus).length;
    const ctx: CalculateUpgradePriceModalContext = {
      pricePerLicence: this.volumeCustomer.pricePerLicence,
      validUntil: new Date(this.volumeCustomer.validUntil),
      intervalInMonths: this.volumeCustomer.invoiceIntervalInMonth,
      amountLicences: amount | 0
    };
    this.ms.openLicencesCalculationModal(ctx).afterClosed().subscribe(value => {

    });
  }

  openSetAllRunningModal() {
    const context: ConfirmationModalContext = {
      headline: "Set all clients to running state",
      content: "Would you really like set all clients to running state?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.setAllClientsRunning(this.volumeCustomer.id).subscribe();
        this.licenceService.findVC(this.volumeCustomer.id).subscribe(value1 => {
          this.licences = value1.volumeClients;
          this.updateTable();
        });
      }
    });
  }

  openRecallAllModal() {
    const context: ConfirmationModalContext = {
      headline: "Recall all volume clients",
      content: "Would you really like to recall all volume clients ?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.recallAllClientAccounts(this.volumeCustomer.id).subscribe((res: string[]) => {
          this.licences.forEach(li => {
            if (res.includes(li.clientId)) {
              li.clientId = li.clientId + ' -> recalled!';
            }
          });
          this.updateTable();
        });
      }
    });
  }

  openDeleteAllModal() {
    const context: ConfirmationModalContext = {
      headline: "Delete all volume clients",
      content: "Would you really like to delete all volume clients?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.deleteAllClients(this.volumeCustomer.id).subscribe(value1 => {
          this.licences = value1.volumeClients;
          this.updateTable();
        });
      }
    });
  }

  openAddLicenceModal() {
    this.ms.openAddSingleLicenceModal().afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.addLicences(this.volumeCustomer.id, [value]).subscribe(value1 => {
          this.licences = value1;
          this.updateTable();
        });
      }
    });
  }

  openAddLicencesAsCSVModal() {
    this.ms.openAddMultipleLicenceModal().afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.addLicences(this.volumeCustomer.id, value).subscribe(value1 => {
          this.licences = value1;
          this.updateTable();
        });
      }
    });
  }

  openConvertModal(licence: VolumeClientTO) {
    const context: ConfirmationModalContext = {
      headline: "Convert volume client",
      content: "Would you really like to convert " + licence.clientId +"?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.convertNonTestUser(licence.id).subscribe(value1 => {
          const index = this.licences.indexOf(licence);
          this.licences[index] = value1;
          this.updateTable();
        });
      }
    });
  }

  openActivateModal(licence: VolumeClientTO) {
    const context: ConfirmationModalContext = {
      headline: "Activate volume clients",
      content: "Would you really like to activate " +licence.clientId + "?",
      severity: "warn"
    };
    this.ms.openConfirmationModal(context).afterClosed().subscribe(value => {
      if (value) {
        this.licenceService.activateOrderedClient(licence.id).subscribe(value1 => {
          const index = this.licences.indexOf(licence);
          this.licences[index] = value1;
          this.updateTable();
        });
      }
    });
  }
}

