import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LogFilterTO, SpinnerService} from "ti-frontend-shared";
import {AccountsService} from "../../services/accounts.service";
import {AccountAdminInfoTO, InvoiceTO} from "../../../shared/generated/transportObjects";
import {LogsService} from "../../services/logs.service";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'pa-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  public account: AccountAdminInfoTO;
  public invoiceDataSource: MatTableDataSource<InvoiceTO>;
  public visibleColumns: string[] = ["created", "startPeriod", "endPeriod", "netAmount", "taxInPercent", "grossAmount", "invoiceType", "invoiceNumber"]

  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    private accountsService: AccountsService,
    private ls: LogsService,
    private ms: ModalService,
    private router: Router,
    @Inject('ENV_NAME') private envName: string,
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    const accountId: number = this.activatedRoute.snapshot.params['accountId'];
    this.accountsService.getAccountInfo(accountId).subscribe(res => {
      this.account = res;
      if (this.account.invoices?.length > 0) {
        this.invoiceDataSource = new MatTableDataSource<InvoiceTO>(this.account.invoices)
      }
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));

  }

  public checkDeleteAccount(): void {
    const ctx: ConfirmationModalContext = {
      approveButtonLabel: "delete", content: `Really delete account ${this.account.owner}? Action cannot be undone.`, headline: "Delete account", severity: "warn"
    }
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.deleteAccount();
      }
    })
  }

  private deleteAccount(): void {
    this.spinner.spin(true);
    this.accountsService.deleteAccount(this.account.id).subscribe(() => {
  this.spinner.spin(false);
  this.router.navigateByUrl("/accounts/search");
}, () => this.spinner.spin(false));
  }

  public get loggingSource() {
    return this.envName === 'p' ? 'polly' : 'polly-' + this.envName;
  }

  public get logsLoader() {
    return (filter: LogFilterTO) => this.ls.loadAllLogs(filter);
  }

}
