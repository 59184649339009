<h2>Add single licence</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Delimiter*</mat-label>
        <input matInput formControlName="delimiter" name="delimiter" type="text" maxlength="1" mminlength="1"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Client usernames (emails)*</mat-label>
        <textarea
          matInput
          formControlName="clientIds"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

