<h2>{{editMode ? 'Edit' : 'Create'}} Business Customer Request</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Company/University*</mat-label>
        <input #cn matInput formControlName="companyName" name="companyName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ cn.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>Firstname</mat-label>
        <input #fn matInput formControlName="contactFirstName" name="contactFirstName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ fn.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Lastname</mat-label>
        <input #ln matInput formControlName="contactLastName" name="contactLastName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ ln.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>
    <div class="two fields">
      <mat-form-field>
        <mat-label>E-Mail-Address</mat-label>
        <input matInput formControlName="contactMailAddress" name="contactMailAddress" type="text" maxlength="100"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phoneNumber" name="phoneNumber" type="text" maxlength="100"/>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Amount of Licenses*</mat-label>
        <input matInput formControlName="amountLicences" name="amountLicences" type="number"/>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

