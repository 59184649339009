<div *ngIf="dueVolumeCustomers && dueVolumeCustomers.length > 0; else noDueVolumeCustomers">
  <table mat-table [dataSource]="tableDataSource">
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef>Customer Name</th>
      <td mat-cell *matCellDef="let vc">
        <span>{{vc.customerName}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="customerId">
      <th mat-header-cell *matHeaderCellDef>Customer No.</th>
      <td mat-cell *matCellDef="let vc">
        <span>{{vc.customerNumber}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="validUntil">
      <th mat-header-cell *matHeaderCellDef>Valid until</th>
      <td mat-cell *matCellDef="let vc">
        <span>{{vc.validUntil | date: "dd.MM.yyyy"}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="licences">
      <th mat-header-cell *matHeaderCellDef>Licences</th>
      <td mat-cell *matCellDef="let vc">
        <span>{{vc.volumeClients.length}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr class="selectable-row" (click)="onVcClicked.emit(vc)" mat-row *matRowDef="let vc; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #noDueVolumeCustomers>
  <div class="no-due-volume-customers">
    <span class="material-icons">person_off</span>
    <div>No due volume customers</div>
  </div>
</ng-template>
