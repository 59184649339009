<div *ngIf="upcomingPayments && upcomingPayments.length > 0; else noUpcomingPayments">
  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="accountOwner" headerText="Account Owner"></mat-text-column>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td class="action-column" mat-cell *matCellDef="let up">
        <span>{{up.amount | currency:'EUR':true}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="nextPaymentDate">
      <th mat-header-cell *matHeaderCellDef>Next billing date</th>
      <td class="action-column" mat-cell *matCellDef="let up">
        <span>{{up.nextPaymentDate| date:"dd.MM.yyyy"}}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="vc">
      <th mat-header-cell *matHeaderCellDef>Volume Customer</th>
      <td class="action-column" mat-cell *matCellDef="let up">
{{up.vc ? 'Yes' : 'No'}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let up; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #noUpcomingPayments>
  <div class="no-upcoming-payments">
    <span class="material-icons">credit_card_off</span>
    <div>No upcoming payments</div>
  </div>
</ng-template>
