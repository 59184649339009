<div *ngIf="contacts && contacts.length > 0; else noContacts">
  <table class="contacts-table" mat-table [dataSource]="tableDataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let contact">
        {{contact.salutation}} {{contact.firstName}} {{contact.lastName}} {{contact.department ? '(' + contact.department +')' : ''}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let contact">
        {{contact.email ||'n/a'}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let contact">
        <span *ngIf="contact.phone && contact.mobile">{{contact.phone}} / {{contact.mobile}}</span>
        <span *ngIf="contact.phone && !contact.mobile">{{contact.phone}}</span>
        <span *ngIf="!contact.phone && contact.mobile">{{contact.mobile}}</span>
        <span *ngIf="!contact.phone && !contact.mobile">n/a</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td class="action-column" mat-cell *matCellDef="let contact">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="openDeleteModal(contact)" mat-menu-item>Delete</button>
          <button (click)="openEditModal(contact)" mat-menu-item>Edit</button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let bcr; columns: visibleColumns"></tr>
  </table>
</div>
<ng-template #noContacts>
  <div class="no-contacts-container">
    <div class="material-icons">supervisor_account</div>
    <div>No contacts currently</div>
  </div>
</ng-template>
