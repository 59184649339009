import {Component, OnInit, ViewChild} from '@angular/core';
import {VolumeCustomerListComponent} from "../volume-customer-list/volume-customer-list.component";
import {SpinnerService} from "ti-frontend-shared";
import {VolumeCustomerService} from "../../services/volume-customers.service";
import {ModalService} from "../../../core/services/modal.service";
import {VolumeCustomerTO} from "../../../shared/generated/transportObjects";
import {Router} from "@angular/router";

@Component({
  selector: 'pa-volume-customer-overview',
  templateUrl: './volume-customer-overview.component.html',
  styleUrls: ['./volume-customer-overview.component.scss']
})
export class VolumeCustomerOverviewComponent implements OnInit {
  volumeCustomers: VolumeCustomerTO[];
  @ViewChild(VolumeCustomerListComponent)
  public volumeCustomerListComponent: VolumeCustomerListComponent;

  constructor(private router: Router, private spinnerService: SpinnerService, private volumeCustomerService: VolumeCustomerService, private modalService: ModalService) {
    this.spinnerService.spin(true);
  }

  ngOnInit(): void {
    this.volumeCustomerService.getAllVC().subscribe(value => this.volumeCustomers = value, () => {
      this.spinnerService.spin(false);
    }, () => {
      this.spinnerService.spin(false);
    });
  }

  addVolumeCustomer() {
    this.modalService.openCreateEditVolumeCustomer().afterClosed().subscribe((value) => {
      if (value) {
        this.volumeCustomerService.createVC(value.customerName).subscribe(value1 => {
          this.volumeCustomers = this.volumeCustomers.filter(value2 => value2.id !== value1.id);
          this.volumeCustomers.push(value1);
          this.volumeCustomerListComponent.updateTable();
        });
      }
    });
  }

  goToDetails($event: number) {
    this.router.navigateByUrl('volume-customers/' + $event + '/details');
  }
}
